@import '../../../../mixins';
@import '../../../../variables.module';

.pageContainer {
  @include container;
}

.tableWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 30px 40px;
}

.tableTitle {
  color: white;
  font-size: 22px;
  font-family: "Poppins-ExtraLight", sans-serif;

}

.contentBox {
  position: relative;
  width: 100%;
  height: 62vh;
  z-index: 1;
  margin-top: 50px;
  padding-top: 16px;
  padding-bottom: 50px;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.16);
    z-index: -1;
  }
}

.cartBtn {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.cartIcon {
  font-size: 15px;
  color: #B2B2B2;
}

.tableInfo {
  width: 200px;
  height: 100px;
  display: flex;
  flex-direction: column;
  padding: 8px 30px;
  background: #771F42;

  &Group {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 5px 0;
    font-family: "Poppins-ExtraLight", sans-serif;
    font-size: 15px;
    color: white;
  }

  .booked {
    padding-right: 13px;
  }

  .star {
    padding-left: 18px;
  }
}

.bookedRectangle {
  width: 55px;
  height: 11px;
  background: #FFFFFF;
}

.availableRectangle {
  width: 55px;
  height: 11px;
  background: #B2B2B2;
}

.titleWrapper {
  display: flex;
  flex-direction: column;

}

.portfolioLogoWrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;

  img {
    max-width: 50px;
  }
}

.portfolioLogo {
  margin-right: 10px;

  img {
    width: 50px;
    height: 50px;
  }
}

.backButtonInner {
  display: flex;
  justify-content: end;
  align-content: center;
}

.backButton {
  display: flex;
  padding: 17px 20px;
  width: 110px;
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.backToOperators {
  display: block;
  margin-right: 5px;
  width: 15px;
  transform: rotate(90deg);
}

.indicator {
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 13px;
  width: 17px;
  height: 17px;
  background: $brightColor;
}