@import "../../../../../../../../../mixins";

.tableInner {
  max-height: 43vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
}

.pdfTable {
  max-width: 900px;
  padding: 20px 40px 40px;
}

.tableShort {
  max-height: 38vh;
}

.usersTable {
  @include table;

  .pdfHead {
    & > tr th {
      position: relative;
    }
  }

  th:nth-child(2),
  td:nth-child(3) {
    min-width: 210px;
  }

  .deletedUser {
    td {
      color: #8D8D8D;
    }
  }

  td {
    & > .actionBtn {
      cursor: pointer;
      text-align: center !important;
    }
  }
}

.contentCenter {
  text-align: center !important;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 20px;
}

.actionBtn {
  border: none;
  padding: 0;
  background-color: transparent;
}

.tooltip {
  background-color: #FF3466 !important;
  padding: 5px 7px !important;
  font-size: 12px;
  opacity: 1;
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 500;
}

.searchResult {
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-height: 850px) {
  .tableInner {
    max-height: 31vh;
  }

  .tableShort {
    max-height: 26vh;
  }
}
