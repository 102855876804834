@import '../../../../../mixins';
@import '../../../../../variables.module';

.page {
  @include basicBackground;

  .pageContainer {
    @include container;
    padding-bottom: 40px;
  }

  .title {
    font-size: 32px;
    font-family: "ITC Avant Garde Bold", sans-serif;
    line-height: 40px;
    color: #FFFFFF;
  }
}

.upperSection {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}

.contentBox {
  position: relative;
  width: 100%;
  height: 66vh;
  z-index: 1;
  margin-top: 50px;
  padding-top: 40px;
  padding-bottom: 50px;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.16);
    z-index: -1;
  }
}


.tiles {
  max-height: 54vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
  padding-left: 34px;
  padding-right: 34px;
  margin-top: 30px;
  padding-bottom: 30px;
  overflow-y: scroll;
}

.allRightsHolderTile {
  grid-column: 1 / -1;
  margin: 0 auto;
  width: 32.5%;
  background-color: inherit;
  border: none;
  padding: 0;

  .cardTitle {
    text-transform: uppercase;
    margin: 0 auto;
  }
}

.tileInner {
  background-color: inherit;
  border: none;
  padding: 0;
}

.tiles::-webkit-scrollbar {
  width: 16px;
}

.tiles::-webkit-scrollbar-track {
  background: #7e1d51;
}

.tiles::-webkit-scrollbar-thumb {
  background: #FF3466;
}

.tiles::-webkit-scrollbar-thumb:hover {
  background: #cc3359;
}

.cardTitle {
  color: #FFFFFF;
  padding-left: 30px;
  font-size: 20px;
  font-family: "Poppins-Light", sans-serif;
}

.imgBox {
  width: 65px;
  height: 65px;
  overflow: hidden;
}

.imgBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.controlsInner {
  display: flex;
  justify-content: center;
  gap: 0 18px;
}

.controlBtn {
  width: 32.5%;
  background-color: inherit;
  border: none;
  padding: 0;

  .cardTitle {
    text-transform: uppercase;
    margin: 0 auto;
  }
}

@media (max-height: 900px) {
  .contentBox {
    height: 62vh;
  }

  .tiles {
    max-height: 48vh;
  }
}

@media (min-width: 1980px) {
  .tiles {
    grid-template-columns: repeat(4, 1fr);
  }
}
