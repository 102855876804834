@import "../../../../../mixins";

.pageInner {
  @include basicBackground;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;
}

.controlOverlay {
  background: rgba(0, 0, 0, 0.16);
  padding: 26px 33px;
}

.controlInner {
  display: flex;
  padding-bottom: 5px;
  font-size: 26px;
  line-height: 32px;
  justify-content: space-between;
  align-items: center;
}

.linksInner {
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;

  & > a:hover {
    background-color: inherit;
  }
}

.notActiveTab {
  @include notActiveLink;
  padding: 0 30px 10px 16px;
}

.activeTab {
  @include activeLink;
  padding: 0 30px 10px 16px;
}

.searchInput {
  @include searchInput;
  width: 512px;
}
