.loginPage {
  width: 100%;
  height: 100vh;
  background: #EFF5F5;
  position: relative;
  font-family: "Poppins", sans-serif;
  transition: 0.3s all;
  display: flex;
}



