@import '../../../src/mixins';

.input {
  @include defaultInput;
  width: 100%;
  margin-top: 8px;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.label {
  @include label;
}

.input:disabled {
  color: #707070;
  background: #f1f1f1;
}

@media (max-height: 800px) {
  .input {
    font-size: 13px;
  }
}
