@import '../../../src/mixins';

.formUnderlayBackground {
  height: 100vh;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(20deg, #6d3fa8, #b30e3f);
}

.resetPasswordModal {
  &Overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins-regular", sans-serif;
    z-index: 100;
    backdrop-filter: brightness(0.5);
  }

  &Container {
    width: 435px;
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 1000;
  }

  &CloseButton {
    position: absolute;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 20px;
    height: 20px;
    top: 17px;
    right: 17px;
    padding: 0;
    cursor: pointer;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &Title {
    color: #161616;
    font-size: 17px;
    margin-top: 20px;
    font-family: "Poppins-regular", sans-serif;
    align-self: flex-start;
    padding-left: 16px;
  }

  &Text {
    color: #161616;
    font-size: 14px;
    font-family: "Poppins-regular", sans-serif;
    margin-top: 15px;
    align-self: flex-start;
    padding-left: 16px;
  }

  &Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
  }

  &InputsSection {
    margin-top: 25px;
    width: 100%;
    padding: 0 16px;
    align-self: flex-start;
  }

  &Label {
    position: relative;
    font-family: "Poppins-regular", sans-serif;
    width: 100%;
    margin-top: 20px;
    color: #525252;
    display: block;
    font-size: 12px;
  }

  &Input {
    @include defaultInput;
    width: 100%;
    background-color: #F4F4F4;
    height: 40px;
    font-size: 12px;
    font-family: "Poppins-regular", sans-serif;
    margin-top: 7px;
  }

  &SuccessMessage {
    color: #5BBEBA;
    font-size: 12px;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.buttons {
  @include actionButtons;

  & > button {
    height: 55px;
    font-size: 16px;
  }
}

.formError {
  @include formErrorBlock;
  margin-top: 5px;
}

.visualPasswordHelper {
  margin-top: 30px;
  margin-bottom: 65px;
  display: flex;
  justify-content: center;
}


