.filters {
  position: relative;
  display: flex;
  column-gap: 5px;
  row-gap: 18px;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-end;

  .inputWrapper {
    min-width: 230px;
    max-width: 370px;
    position: relative;

    .label {
      display: block;
      font-size: 14px;
      width: 100%;
      position: relative;
      font-family: "Poppins-regular", sans-serif;
      color: #FFFFFF;
      margin-bottom: 8px;
    }
  }

  .inputWrapper.separator {
    width: 200px;
    position: relative;
    margin-right: 30px;

    &:after {
      content: "";
      width: 2px;
      background-color: #EA315F;
      height: 91px;
      position: absolute;
      right: -18px;
      bottom: 6px;
    }
  }

  .resetFiltersButton {
    position: absolute;
    top: -2px;
    right: 0;
    border: none;
    font-size: 12px;
    text-decoration: underline;
    background: transparent;
    font-family: "Poppins-regular", sans-serif;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: 0.8;
    }
  }
}

.expander {
  position: absolute;
  right: 8px;
  top: 20px;
  z-index: 100;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  font-size: 20px;

  span {
    font-size: 20px;
  }
}
