@import '../../../src/variables.module';
@import "../../../src/mixins";

.date-input {
  width: 100% !important;
  height: 48px !important;
  line-height: 100% !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-align: left !important;
  border-radius: 0 !important;
  border: none !important;
  font-family: "Poppins-Regular", sans-serif !important;
  position: relative !important;
}

.calendar-header {
  background-color: $brightColor;
}

.field-wrapper {
  width: 50px;
  gap: 3px;

  & .time-fieldset {
    align-items: center;
  }

  & .time--input {
    font-size: 12px !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
  }
}

.custom-years {
  @include calendar-year;
}

.calendar-day {
  @include calendar-day;
}

.custom-months {
  @include calendar-month;
}

.calendar-modal-up {
  bottom: 45px;
}

.calendar-modal-up-right {
  bottom: 45px;
  right: 0;
}

.calendar-modal-down {
  top: 45px;
}

.calendar-modal-down-left {
  left: -45px;
}

.calendar-modal-down-right {
  top: 45px;
  right: 0;
}
