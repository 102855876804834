@import "../../../../../../src/mixins";
@import "../../../../../../src/variables.module";

.tableOverlay {
  background: rgba(0, 0, 0, 0.16);
  min-width: 50%;
  max-width: 50%;
  height: 100%;
}

.tableWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 35px 25px 35px;
  max-height: 60vh;
  position: relative;
  scrollbar-gutter: stable;

  .cellWithCheckbox {
    display: flex;
    align-items: center;

    & > p {
      margin-left: 10px;
    }
  }
}

.tableTitle {
  font-size: 26px;
  line-height: 32px;
  color: #FFFFFF;
  padding: 22px 0 6px 37px;
}

.pricingTable {
  @include table;

  & th, td {
    min-width: 40px;
    font-size: 15px;
  }

  & th:nth-child(3),
  & th:nth-child(4),
  & th:nth-child(5) {
    text-align: right;
  }

  & td:nth-child(3),
  & td:nth-child(4),
  & td:nth-child(5) {
    text-align: right;
  }
}

.tableScroll {
  @include tableScroll;
  scrollbar-gutter: stable;
}

.tableMenu {
  color: $brightColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  font-family: Poppins-SemiBold, sans-serif;
  font-size: 16px;
  line-height: 25px;

  .controlBox {
    display: flex;
    column-gap: 30px;

    .menuButton {
      cursor: pointer;
      color: #FFFFFF;
    }

    .notActiveMenuBtn {
      color: $brightColor;
      cursor: pointer;
    }
  }
}

.tableControl {
  display: flex;
  width: 50px;
  justify-content: space-between;
  margin-left: 10px;

  & > svg {
    cursor: pointer;
  }
}
