@import '../../../../../../../../mixins';
@import '../../../../../../../../variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;

  .formContainer {
    width: 720px;
    max-height: 85%;
    position: fixed;
    color: #161616;
    background-color: #EBEBEB;
    font-family: "Poppins-Regular", sans-serif;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.closeButtonWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 5px 8px 0 0;
  cursor: pointer;

  & > svg {
    width: 30px;
    height: 30px;
    color: #161616;
  }
}

.titleWrapper {
  display: flex;
  justify-content: center;

  & > h6 {
    @include formTitle;
  }
}

.formContent {
  margin: 0 16px 16px 16px;
  padding: 0 16px 45px;
}

.stepsWrapper {
  padding: 20px 16px 30px;
  margin: 0 16px;
}

.twoInputsContainer {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.label {
  display: block;
  font-size: 12px;
  width: 100%;
  position: relative;
  color: #525252;
  margin-bottom: 27px;

  & span {
    color: $brightColor;
  }

  & > div {
    padding-top: 10px;
  }
}

.selectTitle {
  font-size: 12px;
  width: 100%;
  color: #525252;
  padding-bottom: 10px;

  & span {
    color: $brightColor;
  }
}

.formSubTitle {
  font-size: 12px;
  line-height: 16px;
  color: #525252;
  padding-bottom: 8px;

  & span {
    color: $brightColor;
  }
}

.formSubTitleBorder {
  border-bottom: 1px solid #8D8D8D;
  margin-bottom: 21px;
}

.formErrorBlock {
  @include formErrorBlock;
  position: relative;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: -10px;
}

.checkboxes {
  display: flex;
  flex-direction: column;

  & div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  & div:last-child {
    margin: 0;
  }

  & span {
    margin-left: 10px;
    font-size: 14px;
    line-height: 21px;
    color: #161616;
  }
}

.selectLabel {
  font-size: 12px;
  width: 100%;
  color: #525252;
  padding-bottom: 27px;

  & span {
    color: $brightColor;
  }
}

.checkboxes {
  @include checkboxes;
}

.quickSelectionButtons {
  display: flex;
  justify-content: space-between;
  width: 170px;
  padding-left: 10px;

  .quickSelectionButton {
    margin-top: 12px;
    border: 1px #525252 solid;
    border-radius: 0;
    background-color: #F4F4F4;
    color: #161616;;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 12px;
    padding: 4px 7px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;

    &:hover {
      background-color: $brightColor;
      border: 1px $brightColor solid;
      color: white;
    }
  }
}

.formTitle {
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin-bottom: 5px;
}

.coverElement {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.formError {
  @include formErrorBlock;
  text-align: left;
}
