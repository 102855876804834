.card {
  position: relative;
  padding-left: 14px;
  padding-right: 32px;
  width: 100%;
  min-height: 87px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    z-index: -1;
  }
}