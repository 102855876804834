@import "../../../../../../src/mixins";

.page {
  @include basicBackground;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;

  .upperSection {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-top: 20px;
  }

  .title {
    color: white;
    font-family: "ITC Avant Garde Bold", sans-serif;
    font-size: 32px;
    line-height: 40px;
  }
}

.mainContentWrapper {
  width: 100%;
  height: 68vh;
  background: rgba(0, 0, 0, 0.16);
  margin-top: 35px;
  padding: 26px 0 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.tableContainer {
  width: 100%;
  margin-top: 32px;
  @include tableScroll;

  ::-webkit-scrollbar-track {
    margin-top: 60px;
  }
}

.tableWrapper {
  width: 100%;
  height: 52vh;
  overflow-y: scroll;
  position: relative;
  padding-right: 40px;
}

.destinationCol {
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;

  & > .editIcon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.selectCol {
  padding: 0 16px 0 0 !important;
}

.editRow {
  & > td {
    background-color: rgba(0, 0, 0, 0.16);
    vertical-align: baseline;
  }
}

.selectPort {
  max-width: 170px;
}

.scroll {
  max-height: 400px;
  overflow: initial !important;
}

.searchInputInner {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 6px;
}

.search {
  width: 512px;
  margin-right: 55px;
}

.headerClickable {
  &:hover {
    filter: brightness(0.85);
    transition: 0.2s ease-in-out;
  }
}

.headerClickableSelected {
  filter: brightness(0.85);
}
