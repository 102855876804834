@import '../../../../../../mixins';

.overlay {
  @include modalOverlay;
  @include tableScroll;
}

.label {
  @include label;
}

.form {
  @include formScrollable;
}

.hiddenForm {
  display: none;
}

.title {
  @include formTitleUnderLined;
}

.content {
  padding: 27px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 26px;
}

.textarea {
  @include issueFormTextarea;
}

.calendar {
  font-size: 12px;
  position: relative;
  width: 50%;
}

.hiddenCalendar {
  width: 100%;
  position: absolute;
  top: 21px;
  right: 0;

  .calendarInput {
    width: 100% !important;
    height: 40px !important;
    line-height: 100% !important;
    padding: 0 10px !important;
    text-align: left !important;
    border-radius: 0 !important;
    font-family: "Poppins-Regular", sans-serif !important;
    background: transparent;
    border-style: none !important;
    color: transparent !important;
  }
}

.clearCalendar {
  display: block;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 11px;
}

.sectionTitle {
  font-size: 16px;
  margin-bottom: -10px;
  color: #FF3466;
}

.searchBlock {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .searchInput {
    width: 35%;
    margin-bottom: 5px;
  }
}

.buttons {
  @include actionButtons;
}

.openButton {
  font-family: "Poppins-SemiBold", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 48px;
  background-color: #FFFFFF;
  color: #FF3466;
  border: none;
  outline: none;
  cursor: pointer;
}

.formErrorBlock {
  @include formErrorBlock;
}

.tableBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}

.btnDecor {
  font-size: 12px;
  font-weight: bold;
  color: #161616;
  padding-right: 3px;
}

.exitIcon {
  height: 105px;
  width: 105px;
  margin-right: 10px;
}

.isSelected {
  color: #FF3466;
}

.severityLabel {
  display: flex;
  align-items: center;
}

.tooltipWrapper {
  margin-left: 6px;
  max-width: 20px;
}

.tooltipIcon {
  display: block;
  max-width: 100%;
}

.severityTooltip {
  font-family: "Poppins-Regular", sans-serif;
}

.nonSelected {
  color: grey;
}

.endTimeWrapper {
  position: relative;
}

.unresolved {
  position: absolute;
  bottom: 100%;
  left: 61%;
  display: flex;
  align-items: center;

  &Label {
    margin-left: 5px;
  }
}
