@import "../../../../../../src/mixins";
@import "../../../../../../src/variables.module";

.tableOverlay {
  background: rgba(0, 0, 0, 0.16);
  min-width: 50%;
  max-width: 50%;
  height: 100%;
  padding-bottom: 15px;
}

.tableTitle {
  font-size: 26px;
  line-height: 32px;
  color: #FFFFFF;
  padding: 22px 0 11px 30px;
}

.tableMenu {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins-SemiBold, sans-serif;
  font-size: 16px;
  line-height: 23px;
  padding: 0 35px 15px 30px;

  .controlBox {
    display: flex;
    column-gap: 30px;

    .menuButton {
      font-size: 16px;
      font-family: Poppins-SemiBold, sans-serif;
      cursor: pointer;
      color: white;
      border: none;
      background: transparent;
    }

    .notActiveMenuBtn {
      color: $brightColor;
      cursor: default;
    }
  }
}

.tableControl {
  display: flex;
  width: 50px;
  justify-content: space-between;
  margin-left: auto;

  & > button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    & > svg {
      color: white;
    }
  }
}

.tableWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 25px 25px 30px;
  max-height: 56vh;
  scrollbar-gutter: stable;
}

.tableScroll {
  @include tableScroll;

  ::-webkit-scrollbar-track {
    margin-top: 65px;
  }
}

.assignmentTable {
  @include table;
}

.limitedCell {
  max-width: 135px;
  word-break: break-all;
}

.cellWithCheckbox {
  display: flex;
  align-items: center;
  width: 100%;

  .checkbox {
    margin-right: 10px;
  }
}

.deleteIconBig {
  @include deleteIconBig;
}

@media (max-height: 850px) {
  .tableWrapper {
    max-height: 51vh;
  }
}



