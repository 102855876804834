@import "../../../../../../../src/mixins";

td.actions {
  padding-top: 14px;

  & > button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;

    & > svg {
      color: white;
    }
  }
}

td.accordionCell {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-family: "Poppins-SemiBold", sans-serif;
}

.tableChevron {
  transform: rotate(-90deg);
  position: absolute;
  top: 14px;
  left: 0;
  transition: .3s;
  color: $brightColor !important;
}

.tableChevronTop {
  transform: rotate(0deg);
  position: absolute;
  top: 14px;
  left: -3px;
}

.actionBtn {
  cursor: pointer;
}

.accordionRow {
  background: rgba(0, 0, 0, 0.1019607843);

  & > td {
    border-bottom: 6px solid transparent;
  }
}

.userTableTooltip {
  font-family: "Poppins-Regular", sans-serif;
}

.centredCell {
  text-align: center !important;
}

.limitedCell {
  max-width: 120px;
  word-break: break-all;
}
