@import "../../../src/variables.module";

.btnInner {
  display: flex;
  align-items: center;
}

.radioInput {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 19px;
  height: 19px;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 2px;
  }

  &:checked {
    &::after {
      background: $brightColor;
    }
  }
}

.label {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  padding-top: 3px;
  padding-left: 9px;
}
