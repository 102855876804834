.userInfoWrapper {
  background-color: rgba(0, 0, 0, .16);
  margin-right: 13px;
  max-width: 420px;
  flex-shrink: 1;
  width: 100%;
  padding: 0 20px 20px 20px;
  font-family: 'Poppins-Bold', sans-serif;
  color: #FFFFFF;

  & > a {
    background-color: transparent;
  }
}

.backButton {
  border: none;
  background-color: rgba(0, 0, 0, .16);
  padding: 0;
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin-top: 20px;

  & > svg {
    width: 24px;
    height: 24px;
  }
}

.userInner {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top: 63px;

  & > img {
    max-width: 220px;
    height: auto;
  }
}

.userName {
  display: flex;
  font-size: 38px;
  line-height: 46px;
  padding: 22px 0 10px;
  word-break: break-word;
}

.userRole {
  font-size: 26px;
  line-height: 32px;
  padding-bottom: 25px;
}

.totalUsers {
  display: flex;
  justify-content: center;
  font-size: 23px;
  line-height: 34px;
  padding: 15px 0 15px;
  border-top: 2px solid #F4F4F4;
}

.totalWrapper {
  border-top: 2px solid #F4F4F4;
  padding-top: 25px;
}

.totalInner {
  display: flex;
  border-bottom: 2px solid #F4F4F4;
}

.totalInner:first-child {
  & > .totalCell {
    padding-bottom: 20px;
  }
}

.totalInner:last-child {
  border-bottom: none;

  & > .totalCell {
    padding-top: 20px;
  }
}

.totalCell {
  width: 50%;
  border-right: 2px solid #F4F4F4;
  text-align: center;
}

.totalCell:last-child {
  border-right: none;
}

.totalNumber {
  font-size: 50px;
}

.cellName {
  font-size: 14px;
  line-height: 21px;
  padding-top: 5px;
}
