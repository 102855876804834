@import '../../../../mixins';
@import '../../../../variables.module';

.pageInner {
  @include basicBackground;
}

.headerTitle {
  font-size: 32px;
  font-family: "ITC Avant Garde Bold", sans-serif;
  line-height: 40px;
  color: #FFFFFF;
  padding: 40px 0 50px;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;
}

.tableWrapper {
  max-height: 62vh;
  overflow-y: auto;
  padding-right: 15px;
}

.tableWrapperInner {
  padding: 48px;
  color: #FFFFFF;
  background: rgba(0, 0, 0, 0.16);


  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background: #7e1d51;
  }

  ::-webkit-scrollbar-thumb {
    background: #FF3466;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #cc3359;
  }
}

.providerRowEnd {
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);
}

.csvBtn {
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #FFFFFF;
  color: #FFFFFF;
  font-family: Poppins-SemiBold, sans-serif;
}
