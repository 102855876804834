@import "../../../../../src/mixins";

.tableWrapper {
  padding: 0 26px 0 38px;
  position: relative;
  overflow-y: hidden;
}

.searchTableWrapper {
  padding: 0 26px 0 38px;
  position: relative;
  overflow-y: hidden;
}

.tableContainer {
  width: 100%;
  margin-top: 16px;

  @include tableScroll;

  ::-webkit-scrollbar-track {
    margin-top: 60px;
  }
}

.cellLimited {
  max-width: 400px;
  word-break: break-all;
}
