@import "../../../../../../../src/mixins";

.restrictionsWrapper {
  width: 33%;
  background: rgba(0, 0, 0, 0.16);
  padding: 48px 32px 16px;
}

.content {
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
}

.title {
  font-size: 26px;
  padding-bottom: 29px;
  width: 100%;
  color: #FFFFFF;
  line-height: 32px;
  font-family: "Poppins-SemiBold", sans-serif;
  cursor: default;
}

.subTitle {
  font-family: "Poppins-Light", sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-style: italic;
  cursor: default;

  & > span,
  & > span:hover {
    font-family: "Poppins-SemiBold", sans-serif;
    font-style: normal;
    color: #FFFFFF;
  }
}

.checkbox {
  margin-top: 20px;
  display: flex;
  align-items: center;

  & > span,
  & > span:hover {
    font-family: "Poppins-Light", sans-serif;
    font-size: 16px;
    margin-left: 10px;
    color: #FFFFFF;
    cursor: default;
  }
}
