@import "../../../../../../../variables.module";

.tableSort {
  margin: -2px 6px;
  width: 12px;
  height: 12px;
  fill: $brightColor;
}

.tableSortAsc {
  margin: -2px 6px;
  transform: rotateZ(180deg);
  width: 12px;
  height: 12px;
}

.sortingName {
  color: #FFFFFF;
}

.sortingName:hover {
  color: $brightColor;
}

.hiddenTableSort {
  display: none;
}

.sortingInner {
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.sortingInner:hover {
  .hiddenTableSort {
    display: inline-block;
  }
}
