@import "../../../../mixins";

.pageWrapper {
  background-color: #F4F4F4;
  padding-bottom: 72px;
  @include basicBackground;
  min-height: 100vh;
}

.subHeader {
  padding-top: 28px;
  margin-bottom: 35px;

  h1 {
    color: white;
    font-family: "ITC Avant Garde Bold", sans-serif;
    font-size: 32px;
    line-height: 40px;
  }
}

.goBack {
  outline: none;
  display: flex;
  background: transparent;
  border-style: none;
  font-size: 16px;
  column-gap: 10px;
  color: #FFFFFF;
  margin-bottom: 20px;
  cursor: pointer;
  margin-left: -8px;
}


.pageContainer {
  @include container;
  background: transparent;
}


.addBtn {
  max-width: 232px;
  width: 100%;
}

.backButton {
  height: 30px;
  display: flex;
  align-items: center;
  background-color: inherit;
  font-size: 18px;
  border: none;
  cursor: pointer;

  & > svg {
    margin-right: 10px;
  }
}

.addBtnContent {
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 28px;
}

.searchWrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  padding-right: 36px;
  column-gap: 20px;
}

.searchInput {
  @include searchInput;
  width: 512px;
}


.content {
  width: 100%;
  background: rgba(0, 0, 0, 0.16);
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  height: 67vh;
}
