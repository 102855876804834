@import "../../../src/mixins";
@import "../../../src/variables.module";

.cmsContainer {
  position: relative;
  font-family: "Poppins-Regular", sans-serif;
  background: linear-gradient(20deg, #6d3fa8, #b30e3f);
  height: 100vh;
  overflow-y: auto;
}

.pageContainer {
  position: relative;
}

.pageDimmer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.45;
  z-index: 3;
}
