@import "../../../../mixins";
@import "../../../../variables.module";

.chart {
  transform: translateY(-50px);
}

.mainChartLabel {
  display: none;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 4px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.2s ease-in-out;
  font-family: "Poppins", sans-serif;
  pointer-events: none;
}

.mainChartLabelActive {
  display: block;
  opacity: 0.9;
}

.noDataFoundStubWrapper {
  height: 100%;
}
