.rangePicker {
  position: relative;
  width: inherit;
  height: 100%;
  color: #525252;
  font-weight: 500;
  line-height: 21px;
  font-size: 12px;

  & img {
    width: 13px;
    height: 13px;
    position: absolute;
    top: 48%;
    transform: translateY(50%);
    right: 13px;
    cursor: pointer;
    pointer-events: none;
  }
}
