@import "../../../../../../src/mixins";
@import "../../../../../../src/variables.module";

.page {
  @include basicBackground;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;

}

.upperSection {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 20px;
}

.title {
  color: white;
  font-family: "ITC Avant Garde Bold", sans-serif;
  font-size: 32px;
  line-height: 40px;
}

.filters {
  @include bookFixturesFilters;

  .resetFiltersButton {
    @include resetFilterButton;
  }
}

.middleSection {
  width: 100%;
  background: rgba(0, 0, 0, 0.16);
  margin-top: 90px;
  padding-top: 26px;
  padding-bottom: 19px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .addFixtureButton {
    width: 232px;
    height: 40px;
    margin-top: 19px;
    margin-right: 40px;
    background-color: $brightColor;
    line-height: 40px;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 16px;
    font-family: Poppins-Regular, sans-serif;
  }
}

.tableSection {
  width: 100%;
  height: 50vh;
  padding: 0 34px 0 40px;
  background: rgba(0, 0, 0, 0.16);
  position: relative;
  overflow-y: hidden;
  scrollbar-gutter: stable;

  ::-webkit-scrollbar-track {
    margin-top: 77px;
  }
}

.ingestDropdownIcon {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 13px;
  left: 10px;
  cursor: pointer;
}

.tableButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .tableButton {
    background: transparent;
    border: none;
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .editIcon,
  .deleteIcon,
  .infoIcon,
  .timeExtendingIcon,
  .cartIcon,
  .auditIcon {
    width: 100%;
    height: 100%;
  }

  .iconDisabled {
    opacity: 0.5;
  }
}

td.paddingRightWide {
  padding-right: 25px;
}

.indicator {
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 12px;
  width: 17px;
  height: 17px;
  background: $brightColor;
}

.deleteIconBig {
  @include deleteIconBig;
}

.userTableTooltip {
  font-family: Poppins-Regular, sans-serif;
  z-index: 1000;
}

.searchInput {
  @include searchInputPink;
  width: 512px;
  margin-right: 40px;
}

