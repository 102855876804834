.chartLabelData {
  border-radius: 4px;
  padding: 7px 13px;

  .chartLabelText {
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Regular", sans-serif;

    & > span {
      font-weight: 700;
    }
  }
}






