.searchWrapper {
  border: none;
  border-bottom: 1px solid #8D8D8D;
  border-radius: 0;
  padding: 5px 10px;
  min-height: 38px;
  position: relative;
  background-color: #FFFFFF;
}

.chip {
  background: #393939;
  border: none;
  color: #FFFFFF !important;
}

.custom-close {
  margin-left: 6px;
}

.optionListContainer {
  background: #F4F4F4;
  border-radius: 0;
  margin-top: 1px;
  z-index: 15000;
  top: auto;
  bottom: 100%;
}

.multiSelectContainer ul {
  border-radius: 0;
}

.multiSelectContainer li {
  padding: 10px 10px;
  border-bottom: 1px solid #E0E0E0;
  color: #525252;
  font-size: 14px;
  line-height: 21px;
}

.multiSelectContainer li:hover {
  background: inherit;
  color: #525252;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.highlightOption {
  background: #F4F4F4;
  color: #525252;
}

.checkbox {
  display: none;
}

.multiSelectContainer {
  .checkbox {
    &:checked + :before {
      content: '';
      width: 15px;
      height: 15px;
    }

    z-index: 15000;
  }

  .checkbox {
    appearance: none;
    position: relative;
    display: inline-block;
    height: 15px;
    width: 15px;
    background: white;
    border-radius: 2px;
    border: 1px solid #E0E0E0;
    margin-right: 10px;
    cursor: pointer;

    &:before {
      content: '';
      width: 17px;
      height: 17px;
      position: absolute;
      left: -1px;
      top: -4px;
    }

    &:checked::before {
      background-image: url(../../img/checkedIcon.svg);
    }
  }

  .option {
    display: flex;
    align-items: center;
  }
}



