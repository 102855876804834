@import "../../../../../mixins";

.pageInner {
  @include basicBackground;
}

.billingWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;
}

.titleInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 34px;
}

.title {
  @include pageTitle;
}

.tableWrapper {
  background-color: rgba(0, 0, 0, .16);
  padding: 0 23px;
  width: 100%;
}

.tabsWrapper {
  display: flex;
  padding: 22px 0;
  border-bottom: 2px solid #F4F4F4;
  font-size: 26px;
  line-height: 32px;

  & > a:hover {
    background-color: inherit;
  }
}

.notActiveTab {
  @include notActiveLink;
  margin-right: 46px;
}

.activeTab {
  @include activeLink;
  margin-right: 46px;
}


@media screen and (max-width: 1200px) {
  .notActiveTab {
    margin-right: 20px;
  }

  .activeTab {
    margin-right: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .notActiveTab {
    margin-right: 20px;
    font-size: 22px;
  }

  .activeTab {
    margin-right: 20px;
    font-size: 22px;
  }
}
