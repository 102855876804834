@import "../../../../../../../src/mixins";

.actions {
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0 10px;
  padding-right: 10px;
  padding-left: 15px;
  vertical-align: middle;

  & > button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
}

td.accordionCell {
  position: relative;
  cursor: pointer;
  font-family: "Poppins-SemiBold", sans-serif !important;
  padding-left: 20px;
}

.tableChevron {
  position: absolute;
  top: 14px;
  left: 0;
  transition: .3s;
  color: $brightColor !important;
}

.tableChevronTop {
  transform: rotate(-180deg);
  position: absolute;
  top: 12px;
  left: 0;
}

.deleteBtn {
  cursor: pointer;
}

.accordionRow {
  background: rgba(0, 0, 0, 0.1019607843);

  & > td {
    border-bottom: 6px solid transparent;
  }
}
