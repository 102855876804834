@import '../../../../../../src/mixins';
@import '../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  @include tableScroll;

  ::-webkit-scrollbar-track {
    margin-top: 50px;
  }
  z-index: 1000;

  .form {
    @include formScrollable;

    .title {
      @include formTitleUnderLined;
    }

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      padding: 40px 27px 40px 27px;
      overflow-y: auto;
      overflow-x: hidden;

      .inputInner {
        width: 100%;
        margin-bottom: 32px;
      }

      .label {
        @include label;

        .genderBlock {
          display: flex;
          margin-top: 10px;
          column-gap: 30px;

          .radioInner {
            cursor: pointer;
            padding: 4px
          }
        }
      }
    }
  }

  .buttons {
    @include actionButtons;

    button {
      cursor: pointer;
    }
  }
}

.formErrorBlock {
  @include formErrorBlock;
  position: absolute;
  padding-top: 5px;
  padding-right: 15px;
}

.extraTime {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: default;
  margin-bottom: 20px;

  & span {
    margin-right: 10px;
  }

  & span:hover {
    color: $brightColor;
  }
}

.uploadImg {
  width: 60px;
  height: 60px;
}

.formSubTitle {
  font-size: 12px;
  line-height: 16px;
  color: #525252;
  padding-bottom: 8px;

  & span {
    color: $brightColor;
  }
}

.formSubTitleBorder {
  border-bottom: 1px solid #8D8D8D;
  margin-bottom: 21px;
}

.uploadLabel {
  width: 100%;
  margin-bottom: 25px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px dashed #8D8D8D;
  font-size: 12px;
  font-weight: bold;
  color: $brightColor;
  transition: 0.1s all ease-in-out;

  &:focus,
  &:hover,
  &:active {
    border-radius: 0;
    border: 2px solid $brightColor;
    outline: none;
  }

  &.dragActive {
    background-color: #E8E8E8;
  }

  &.uploaded {
    background-color: #E0E0E0;
  }
}

.uploadTextSection {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 400px;
  height: 100%;
  line-height: 23px;
}

.formTitle {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.uploadTitle {
  font-size: 14px;
  color: #FF3466;
  font-weight: bold;
}

.uploadDescription {
  color: #808080;
  margin-top: auto;
  font-style: italic;
  font-weight: initial;
  line-height: 25px;
}

.uploadInput {
  display: none;
}

.coverElement {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.oneInputContainer {
  display: flex;

  & > label {
    max-width: 100%;
  }
}



