@import '../../../../../src/mixins';
@import '../../../../../src/variables.module';

.page {
  @include basicBackground;

  .pageContainer {
    @include container;
    padding-bottom: 40px;
  }

  .title {
    font-size: 32px;
    font-family: "ITC Avant Garde Bold", sans-serif;
    line-height: 40px;
    color: #FFFFFF;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}

.contentBox {
  position: relative;
  width: 100%;
  height: 61vh;
  z-index: 1;
  margin-top: 50px;
  padding-bottom: 50px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.16);
    z-index: -1;
  }
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 56px;
  padding-top: 32px;
  padding-right: 17px;

  .title {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 28px;
    font-family: "Poppins-Light", sans-serif;
  }
}

.searchBox {
  position: relative;
  width: 512px;

  .searchIcon {
    position: absolute;
    top: 25px;
    right: 20px;
  }
}

.cardContent {
  max-height: 44vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
  padding-left: 34px;
  padding-right: 34px;
  margin-top: 30px;
  padding-bottom: 30px;
  overflow-y: auto;
}

.cardContent::-webkit-scrollbar {
  width: 16px;
}

.cardContent::-webkit-scrollbar-track {
  background: #7e1d51;
}

.cardContent::-webkit-scrollbar-thumb {
  background: #FF3466;
}

.cardContent::-webkit-scrollbar-thumb:hover {
  background: #cc3359;
}

.cardTitle {
  display: flex;
  color: #FFFFFF;
  align-items: center;

  h6 {
    font-size: 15px;
    font-family: "Poppins-Light", sans-serif;
    margin-left: 10px;
  }

  .favStar {
    cursor: pointer;
    background-color: inherit;
    border: none;
    padding: 0;
  }
}

.imgBox {
  height: 42px;
  overflow: hidden;
}

.imgBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.deleteIconBig {
  width: 105px;
  height: 105px;
}

@media (max-height: 900px) {
  .contentBox {
    height: 62vh;
  }

  .cardContent {
    max-height: 48vh;
  }
}

@media (min-width: 1980px) {
  .cardContent {
    grid-template-columns: repeat(4, 1fr);
  }
}
