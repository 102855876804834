@import '../../../../../../../../src/mixins';
@import '../../../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;

  .form {
    width: 512px;
    min-height: 230px;
    background: #F4F4F4;
    font-family: "Poppins-Regular", sans-serif;

    .decor {
      height: 65px;
      background: $brightColor;
      display: flex;
      justify-content: center;
      align-items: center;

      .attention {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 3px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        font-size: 32px;
      }
    }

    .content {
      padding: 15px;

      .message {
        font-size: 18px;
        line-height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
    }

    .buttons {
      @include actionButtons;
      margin-top: 35px;

      .cancelButton, .submitButton {
        cursor: pointer;
      }
    }
  }
}

.property {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
  cursor: default;
  color: $brightColor;

  & > span {
    display: inline-block;
    padding-top: 10px;
    white-space: nowrap;
    font-size: 15px;
  }
}

.question {
  font-size: 18px;
  line-height: 30px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
