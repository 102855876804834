@import "../../../mixins";
@import "../../../variables.module";

.checkoutPage {
  @include basicBackground;
}

.pageContainer {
  @include container;

  .upperSection {
    position: relative;
    padding-top: 20px;
  }

  .checkoutTitle {
    color: white;
    font-family: "ITC Avant Garde Bold", sans-serif;
    font-size: 32px;
    line-height: 40px;
  }

  .checkoutDescription {
    color: white;
    font-family: "Poppins-Light", sans-serif;
    font-size: 27px;
    margin-top: 15px;
  }

  .closeButton {
    position: absolute;
    top: 15px;
    right: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 35px;
    height: 35px;
  }

  .closeIcon {
    width: 100%;
    height: 100%;
    color: white;
  }

  .mainContentWrapper {
    width: 100%;
    height: 55vh;
    background: rgba(0, 0, 0, 0.16);
    margin-top: 5vh;
    padding: 26px 40px 45px 0;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .cartEmptyText {
    color: white;
    padding-left: 40px;
  }

  .tableContainer {
    width: 100%;
    margin-top: 16px;

    ::-webkit-scrollbar {
      width: 16px;
    }

    ::-webkit-scrollbar-track {
      background: #7e1d51;
    }

    ::-webkit-scrollbar-thumb {
      background: #FF3466;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #cc3359;
    }
  }

  .tableWrapper {
    height: 43vh;
    padding: 0 40px 50px 40px;
    position: relative;
    overflow-y: scroll;

    & > table {
      table-layout: fixed !important;

      td {
        font-family: Poppins-SemiBold, sans-serif;
      }
    }
  }

  .deleteButton {
    position: absolute;
    background: transparent;
    border: none;
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    left: 25px;
  }

  .deleteInner {
    background-color: inherit;
    border: none;
    cursor: pointer;
    padding: 8px 0 0 0;
  }

  .deleteMargin {
    margin-bottom: 15px;
  }

  .deleteIcon {
    width: 19px;
    height: 19px;
    color: #FFFFFF;
  }

  .checkoutFooter {
    .buttons {
      margin-top: 15px;
      @include actionButtons;
      margin-left: auto;
      width: 35%;
    }
  }
}

.deleteBtn {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 48px;
  right: 45px;
}

.deleteFixtureBtn {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 35px;
  right: 45px;
}

.deleteCol {
  position: relative;
  width: 10%;
  text-align: center !important;
  vertical-align: top;
}

.deleteFixtureMargin {
  margin-bottom: 5px;
}

.propertyBtn {
  background-color: inherit;
  border: none;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  padding: 0 10% 0 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  font-family: Poppins-SemiBold, sans-serif;
  white-space: break-spaces;
  text-align: left;
  position: relative;
}

.expandIcon {
  position: absolute;
  top: 0;
  right: 100px;
  width: 18px;
  height: 18px;
}

.fixtureCountInner {
  width: 18% !important;
  white-space: pre-wrap !important;
  word-break: break-word;
  text-align: center !important;
}

.countInputInner {
  max-width: 90px;
  display: inline-block;

  & > input {
    height: 25px !important;
  }
}

.fixtureData {
  padding-top: 15px;
  font-family: Poppins-Light, sans-serif;
}

.fixtureDataInner {
  display: flex;
  justify-content: space-between;
  font-family: Poppins-Light, sans-serif;
  padding: 15px 20px 0;
}

.availableFixturesInner {
  text-align: center !important;
  width: 19%;
}

.providerRowEnd {
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);
}

.propertyInner {
  width: 30%;
}

.propertyInnerBF {
  width: 20%;
}

.providerInner {
  width: 13%;
}

.sportInner {
  width: 10%;
}

.sportInnerBF {
  width: 30%;
}

@media screen and (max-width: 1300px) {
  .tableWrapper {
    td {
      font-size: 12px !important;
    }
  }
}


@media screen and (max-width: 1400px) {
  .deleteBtn {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 48px;
    right: 26px;
  }
}
