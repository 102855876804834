.billingTable {
  & > table {
    width: 100%;
    table-layout: fixed;
    color: #FFFFFF;
    background-color: inherit;
    font-size: 16px;
    line-height: 25px;

    thead tr th {
      position: sticky;
      top: 0;
    }

    & th {
      border-bottom: 1px solid #F4F4F4;
      font-family: 'Poppins-Bold', sans-serif;
      padding: 13px 0;
      text-align: start;
    }

    td {
      font-family: 'Poppins-Regular', sans-serif;
      padding: 10px 0;
    }
  }
}
