@import "../../../../variables.module";
@import "../../../../mixins";

.usersDropdown {
  position: absolute;
  width: 300px;
  top: 35px;
  left: -15px;
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
  z-index: 7;
}

.searchUser {
  @include searchInput;
  margin-right: 30px;
  width: 300px;
  position: sticky;
}

.usersList {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;

  @include appScroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
}

.userItem {
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F4F4F4;
  background-color: white;
  color: #161616;
  padding: 12px 18px 0 15px;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
  position: relative;

  &:hover {
    background-color: #E8E8E8;
    color: #161616;
  }
}
