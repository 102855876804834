@import "../../../../../../src/mixins";

.pageWrapper {
  @include basicBackground;
  overflow-y: hidden;
}

.container {
  @include container;
  padding-bottom: 40px;
}

.contentWrapper {
  background: rgba(0, 0, 0, 0.16);
  margin-top: 50px;
  padding: 26px 40px 45px 40px;
  max-height: 60vh;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  .title {
    font-size: 32px;
    font-family: "ITC Avant Garde Bold", sans-serif;
    color: #FFFFFF;
  }
}

.tableControls {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;

  .searchInput {
    @include searchInputPink;
    width: 512px;
  }
}

.topButtons {
  @include topButtons;
}
