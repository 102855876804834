.bookmarks {
  .bookmarksControls {
    display: flex;
    justify-content: flex-end;
    column-gap: 35px;
    padding-top: 15px;
    margin-bottom: 40px;
  }

  .bookmarksTitle {
    .bookmarksTitleText {
      line-height: 27px;
      font-size: 14px;
      font-family: 'Poppins-Regular', sans-serif;
      color: #FFFFFF;
    }

    .createBookmarkButton {
      display: flex;
      align-items: center;
      column-gap: 10px;
      cursor: pointer;
      border: none;
      background: transparent;
      padding: 0;

      svg {
        margin-right: 0 !important;
        margin-top: -3px;
      }
    }
  }
}

.namesList {
  width: 110px;
  text-align: left;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.16px;
  color: #525252;
}

@import "../../../../mixins";
@import "../../../../variables.module";

.valuesList {
  width: 80px;
  text-align: left;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.16px;
  color: #525252;
}

.bookmarksListExtended {
  width: 100%;
  overflow: auto;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  height: 125px;
  font-size: 14px;
  line-height: 38px;
  color: $brightColor;
  opacity: 1;

  & > li {
    width: 350px;
    height: 38px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
    padding-left: 16px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;

    &.extended {
      width: 100%;
    }

    &:hover {
      background-color: #E8E8E8;
    }

    &:active {
      background-color: #C6C6C6;
    }
  }

}

.bookmarksList {
  max-width: 700px;
  overflow: hidden;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 125px;
  font-size: 14px;
  line-height: 38px;
  color: $brightColor;
  opacity: 1;

  &.extended {
    height: fit-content;
  }

  & > li {
    width: 350px;
    height: 38px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    padding-left: 16px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;

    &.extended {
      width: 100%;
    }

    &:hover {
      background-color: #E8E8E8;
    }

    &:active {
      background-color: #C6C6C6;
    }
  }
}

.bookmarksShowButton {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid $brightColor;
  color: $brightColor;
  padding: 4px 7px;
  font-family: "Poppins-Regular", sans-serif;
  background-color: white;
  transition: 0.2s all ease-in-out;

  &:hover {
    background-color: #E8E8E8;
  }

  &:active {
    background-color: #C6C6C6;
  }

  &:focus {
    outline: none;
    background-color: #E8E8E8;
  }
}
