.dropdown-tooltip {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 1px !important;
  width: 30px;
}

.tooltip-wrapper > div.react-tooltip {
  background-color: #FF3466 !important;
  padding: 5px 7px !important;
  font-size: 12px;
  opacity: 1;
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 10000 !important;

}
