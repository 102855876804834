@import "../../../../../../src/mixins";
@import "../../../../../../src/variables.module";

.upcomingPage {
  @include basicBackground;
}

.downloads {
  @include downloadMenu;
}

.upcomingPageContainer {
  @include container;
  padding-bottom: 40px;

  .upperSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 20px;
  }

  .title {
    color: white;
    font-family: "ITC Avant Garde Bold", sans-serif;
    font-size: 32px;
    line-height: 40px;
  }

  .filters {
    @include bookFixturesFilters;

    .resetFiltersButton {
      @include resetFilterButton;
    }
  }
}

.middleSection {
  width: 100%;
  background: rgba(0, 0, 0, 0.16);
  margin-top: 90px;
  padding-top: 26px;
  padding-bottom: 19px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tableSection {
  width: 100%;
  height: 56vh;
  padding: 0 34px 10px 40px;
  background: rgba(0, 0, 0, 0.16);
  position: relative;
  overflow-y: hidden;
  scrollbar-gutter: stable;

  ::-webkit-scrollbar-track {
    margin-top: 64px;
  }
}

.ingestDropdownIcon {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 13px;
  left: 10px;
  cursor: pointer;
}

.tableButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .tableButton {
    background: transparent;
    border: none;
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .editIcon,
  .deleteIcon,
  .infoIcon,
  .auditIcon,
  .timeExtendingIcon {
    width: 100%;
    height: 100%;
  }
}

.deleteIconBig {
  width: 105px;
  height: 105px;
}

.userTableTooltip {
  font-family: Poppins-Regular, sans-serif;
  z-index: 1000;
}

.searchInput {
  @include searchInputPink;
  width: 512px;
  margin-right: 40px;
}

.ingestDropdown {
  position: absolute;
  top: 45px;
  left: 40px;
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
  z-index: 1;

  .ingestItem {
    width: 145px;
    height: 39px;
    border-bottom: 1px solid #F4F4F4;
    background-color: white;
    color: #161616;
    padding-left: 15px;
    line-height: 40px;
    cursor: pointer;
    transition: 0.1s all ease-in-out;

    &:hover {
      background-color: #E8E8E8;
    }
  }
}

td.ingestCell {
  padding-left: 0;

  .ingestBtn {
    width: 88px;
    height: 28px;
    padding: 0;
    margin: 0;
    outline: none;
    background: #FF3466;
    border: none;
    font-size: 15px;
    line-height: 28px;
    font-family: "Poppins-Regular", sans-serif;
    color: white;
    cursor: pointer;
  }

  .updateMode {
    border: 1px solid #FF3466;
    background: transparent;
  }
}

.highlightedRow {
  background-color: rgba(255, 255, 255, 0.27);
  transition: background-color 1s ease-in-out;
}

.chevronUp {
  transform: rotate(180deg);
}










