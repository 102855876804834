@import '../../../../../../src/mixins';
@import '../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 462px;
    min-height: 500px;
    height: 630px;
    position: fixed;
    color: #161616;
    background-color: #F4F4F4;
    z-index: 10;
    font-family: "Poppins-Regular", sans-serif;

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
      height: 100%;
      padding-top: 17px;
      padding-right: 17px;
      padding-left: 17px;
    }

    .title {
      font-family: inherit;
      font-size: 20px;
      color: #2B2A2B;
      font-weight: bold;
      margin-bottom: 2px;
    }

    .label {
      @include label;
    }

    .input {
      @include defaultInput;
    }

    .buttons {
      @include actionButtons;
    }
  }
}

.formErrorBlock {
  @include formErrorBlock;
  position: absolute;
  width: 100%;
  text-align: center;
  padding-top: 15px;
  padding-right: 40px;
}
