@import '../../mixins';
@import '../../variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;

  .form {
    width: 500px;
    min-height: 230px;
    background: #F4F4F4;
    font-family: "Poppins-Regular", sans-serif;

    .decor {
      height: 65px;
      background: $brightColor;
      display: flex;
      justify-content: center;
      align-items: center;

      .attention {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 3px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        font-size: 32px;
      }
    }

    .content {
      padding: 15px 15px 0 15px;

      .message {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
      }
    }

    .buttons {
      @include actionButtons;
      margin-top: 30px;

      .cancelButton, .submitButton {
        cursor: pointer;
      }
    }
  }
}

.reasonWrapper {
  width: 100%;
}

.reasonInner {
  width: 100%;
  padding-top: 20px;
}

.formErrorBlock {
  @include formErrorBlock;
  position: absolute;
  padding-right: 15px;
}

.radioButton {
  padding-top: 25px;
}

.checkboxLabel {
  display: inline-block;
  padding-left: 10px;
  margin-top: 25px
}

.label {
  @include label;

  & > input {
    margin-top: 8px;
  }
}
