@import './reset.css';
@import './variables.module';

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
  url("./fonts/Poppins-Bold.ttf") format("trueType");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
  url("./fonts/Poppins-Regular.ttf") format("trueType");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
  url("./fonts/Poppins-SemiBold.ttf") format("trueType");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
  url("./fonts/Poppins-Light.ttf") format("trueType");
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: local("Poppins-ExtraLight"),
  url("./fonts/Poppins-ExtraLight.ttf") format("trueType");
}

@font-face {
  font-family: 'ITC Avant Garde Bold';
  src: url("./fonts/ITC Avant Garde Gothic LT Bold.otf") format('opentype');
}

@font-face {
  font-family: 'ITC Avant Garde Regular';
  src: url("./fonts/ITC Avant Garde Gothic Book Regular.otf") format('opentype');
}

.container {
  padding: 0 137px;
  position: relative;
  background: #F4F4F4;
}

.test {
  visibility: hidden;
  height: 0;
  width: 0;
}

text::selection {
  background: transparent;
  color: inherit;
}

.no-print {
  display: none !important;
}


