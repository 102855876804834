@import "../../../../../../mixins";

.headerContainer {
  background: #FFFFFF;
  margin-bottom: 64px;
}

.pageContainer {
  @include container;
}

.subheader {
  background: #FFFFFF;
  padding: 48px 0 53px 15px;
}

.headerTitle {
  font-family: Poppins-Light, sans-serif;
  font-size: 54px;
  line-height: 64px;
}

.totalData {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  line-height: 22px;
}

.totalDataTitles {
  & p {
    padding-bottom: 16px;
    padding-right: 67px;
    line-height: 22px;
    font-weight: 700;
    white-space: nowrap;
  }

  & :last-child {
    padding-bottom: 0;
  }
}

.totalDataValues {
  & p {
    padding-bottom: 16px;
    white-space: nowrap;
  }

  & :last-child {
    padding-bottom: 0;
  }
}
