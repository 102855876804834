@import '../../../../../mixins';

.tableContainer {
  height: 500px;
  overflow-y: auto;
}

.tableScroll {
  @include tableScroll;
}
