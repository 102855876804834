@import '../../../../../src/mixins';
@import '../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;

  .alertsListModalWrapper {
    position: relative;
  }

  .closeButton {
    width: 31px;
    height: 31px;
    position: absolute;
    cursor: pointer;
    top: 6px;
    right: 6px;
    border: none;
    background-color: transparent;
    z-index: 1001;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  .alertsListModal {
    width: 500px;
    min-height: 250px;
    max-height: 80vh;
    padding: 5px 27px 10px 27px;
    background: #EBEBEB;
    font-family: "Poppins-Regular", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow: auto;
    z-index: 1000;
    position: relative;

    .title {
      position: relative;
      height: 40px;
      font-size: 18px;
      line-height: 40px;
      font-family: "Poppins-Regular", sans-serif;
      font-weight: 600;
      color: black;
      text-align: center;

      &:after {
        content: "";
        position: absolute;
        height: 2px;
        width: 200px;
        background: $brightColor;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .decor {
      height: 50px;
      width: 50px;
      margin-top: 10px;
      margin-bottom: 15px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: $brightColor;
    }

    .bellIconBig {
      width: 30px;
      height: 30px;
    }

    .content {
      width: 100%;
      padding: 15px;
      background-color: white;
      position: relative;
      margin-bottom: 5px;
      text-align: center;
    }

    .text {
      font-size: 16px;
      margin-bottom: 15px;
      margin-top: 10px;
      color: $brightColor;
      font-weight: 600;
    }

    .textTime {
      width: 100%;
      text-align: left;
      font-size: 11px;
      font-style: italic;
      color: black;
      font-weight: 100;
    }

    .textLarge {
      font-size: 18px;
      color: black;
      line-height: 25px;
      font-weight: 600;
    }

    .buttons {
      @include actionButtons;
      justify-content: center;
      margin-top: 25px;

      & > button {
        height: 28px;
        font-size: 14px;
        width: 50%;
      }

      .cancelButton, .submitButton {
        cursor: pointer;
      }
    }
  }
}

