.downloadContainer {
  display: flex;
  height: 100%;
  font-size: 14px;
  line-height: 21px;
}

.downloadItem {
  display: flex;
  align-items: center;
  border-left: 1px solid #525252;
  padding: 0 32px 0 16px;
  cursor: pointer;
  color: #FFFFFF;

  & svg {
    margin-left: 32px;
  }

  &:hover {
    color: #EA315F;
  }
}

.downloadItem.disabledButton > svg:hover {
  color: #A8A8A8;
}

.downloadItem:last-child {
  padding: 0 0 0 16px;
}

.disabledCsvWrapper {
  display: flex;
  align-items: center;

  .downloadType:hover {
    color: #A8A8A8;
  }
}

.enabledCsvWrapper {
  display: flex;
  align-items: center;
  border-left: 1px solid #525252;
  padding: 0 0 0 16px;

  & svg {
    margin-left: 32px;
  }

  &:hover {
    color: #EA315F;
  }
}

.disabledButton {
  color: #A8A8A8;
  cursor: default;

  span:hover {
    color: #A8A8A8;
  }

  &:hover {
    color: #A8A8A8;
  }
}
