@import '../../../../../../src/mixins';
@import '../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  @include tableScroll;

  ::-webkit-scrollbar-track {
    margin-top: 50px;
  }
  z-index: 1000;

  .form {
    @include formScrollable;

    .title {
      @include formTitleUnderLined;
      position: absolute;
      top: 0;
      z-index: 10;
      background-color: #EBEBEB;
    }

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      padding: 85px 27px 80px 27px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .label {
      @include label;
    }

    .input {
      @include defaultInput;
    }

    .buttons {
      @include actionButtons;
    }
  }
}

.formErrorBlock {
  @include formErrorBlock;
}
