@import "../../mixins";

.pageContainer {
  @include container;
  padding-top: 28px;
}

.subHeaderInner {
  display: flex;
  justify-content: space-between;
}

.subHeaderTitle {
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  width: 100%;
}

.title {
  @include pageTitle;
}

.totalData {
  display: flex;
  font-size: 15px;
  line-height: 20px;
  font-family: Poppins-SemiBold, sans-serif;
  text-align: center;
  padding-bottom: 17px;

  & > div {
    padding: 0 25px 8px;
  }

  & div:first-child {
    border-left: 2px solid rgba(255, 255, 255, 0.33);
  }

  & div:last-child {
    border-left: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
  }

  .numbers {
    font-size: 48px;
    line-height: 64px;
  }
}
