@import '../../../../../../../../src/mixins';
@import '../../../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;

  @include tableScroll;

  ::-webkit-scrollbar-track {
    background-color: #cecfd0;
    margin-top: 198px;
  }

  .form {
    width: 512px;
    height: 85%;
    background-color: #EBEBEB;
    z-index: 10;
    font-family: "Poppins-Regular", sans-serif;
    position: relative;
    padding-bottom: 48px;

    .content {
      display: flex;
      flex-direction: column;
      height: 98%;
      row-gap: 26px;
      padding: 0 11px 10px 27px;
      overflow: auto;
    }

    .title {
      @include formTitleUnderLined;

      &:after {
        width: 230px;
      }
    }

    .inputModeButtons {
      margin-top: 20px;
      margin-bottom: 8px;
      gap: 6px;
      display: flex;
      justify-content: start;

      .inputModeButton {
        width: 125px;
        height: 27px;
        cursor: pointer;
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 13px;
        line-height: 23px;
        text-align: center;
        background-color: white;
        color: $brightColor;
        border: 1px solid $brightColor;
      }

      .inputModeButtonActive {
        color: white;
        background-color: $brightColor;
      }
    }

    .label {
      @include label;

      & span {
        color: $brightColor;
      }
    }

    .input {
      @include defaultInput;
    }

    .buttons {
      @include actionButtons;
      position: absolute;
      bottom: 0;
    }
  }

  .formInputWrapper {
    position: relative;
  }

  .boro {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 14px;
    cursor: default;

    & span {
      margin-right: 10px;
    }

    & span:hover {
      color: black;
    }
  }

}

.formErrorBlock {
  @include formErrorBlock;
  position: relative;
  text-align: left;
}

.formErrorBottomBlock {
  @include formErrorBlock;
  position: relative;
}
