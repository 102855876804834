@import '../../../../../../../mixins';

.wrapper {
  @include modalOverlay;
}

.form {
  height: 355px;
  width: 600px;
  position: relative;
  background: white;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;

  .tableBackground {
    background: #E3E3E3;
    padding: 30px;
    height: 222px;
    overflow: auto;
    margin-bottom: 10px;

    table {
      border-bottom: 2px solid #EA315F;
      border-top: 2px solid #EA315F;

      th {
        color: #EA315F;
        padding: 8px;
      }

      td {
        border-top: none;
        border-bottom: none;
        color: black;
        padding: 8px;
      }
    }
  }

}

.checkbox {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 2rem !important;
}

.closePopup {
  position: absolute;
  right: 0.5rem;
  top: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;
}

.title {
  @include issueFormTitle;
}

.editIcon {
  width: 100%;
  height: 100%;
}

.auditIcon {
  filter: invert(1);
  width: 90%;
  height: 90%;
}

.buttons {
  @include actionButtons;
}

.tableButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tableButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
}

.tableInner td {
  margin: 10px;
  height: 100px !important;
  background-color: #EA315F;
}

.fixtureTableCheckbox {
  padding-left: 48px;
}

.fixtureTable {
  @include table;

  th {
    color: #EA315F;
  }

  td {
    color: black;

    &:last-child {
      transform: translateX(18px);
    }
  }
}

.tableRowTitle {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleTooltip {
  z-index: 10;
}
