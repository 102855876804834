@import '../../../../../../../../src/mixins';

.SCVForm {
  position: relative;
  width: 512px;
  height: 450px;
  color: #161616;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #EBEBEB;

  .content {
    padding: 50px 27px 0 27px;
    width: 100%;
    margin-bottom: auto;
    margin-top: 22px;

    .btnWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 40%;
      width: 100%;
      position: relative;

      .fileName {
        font-size: 14px;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: -30px;

        .file {
          color: #EA315F;
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      .btn {
        width: 100%;
        height: 52px;
        line-height: 52px;
        color: #FFFFFF;
        background-color: #B23553;
        text-align: center;
        cursor: pointer;
        border: none;
        padding: 0;
        margin: 24px 0 0 0;
        font-size: 16px;
        font-family: Poppins-Regular, sans-serif;
      }
    }
  }

  .uploadLabel {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    cursor: pointer;
    line-height: 48px;
    width: 100%;
  }

  .buttons {
    @include actionButtons;
  }
}


