.tabInner {
  position: absolute;
  width: 100%;
  display: flex;
  z-index: 10;

  .tab {
    height: 48px;
    background: #FFFFFF;
    color: #B2B2B2;
    cursor: pointer;
    font-size: 20px;
    line-height: 48px;
    text-align: center;
    width: 50%;
    font-weight: bold;
  }

  .tabActive {
    position: relative;
    height: 48px;
    color: #000000;
    background: #EBEBEB;
    cursor: pointer;
    font-size: 20px;
    line-height: 48px;
    text-align: center;
    width: 50%;
    font-weight: bold;

    &:after {
      content: "";
      width: 65%;
      height: 2px;
      background-color: #EA315F;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .csvTooltip {
    color: #FFFFFF;
    z-index: 100;
    background: #EA315F;
  }
}

