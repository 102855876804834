@import '../../../../../src/mixins';
@import '../../../../../src/variables.module';

.page {
  @include basicBackground;

  .pageContainer {
    @include container;
    padding-bottom: 40px;
  }
}

.title {
  font-size: 32px;
  font-family: "ITC Avant Garde Bold", sans-serif;
  line-height: 40px;
  color: #FFFFFF;
}

.upperSection {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}

.addPropertyToCartButton {
  margin-top: 7px;
  width: 256px;
  height: 48px;
  background-color: $brightColor;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif;
}

.addPropertyToCartState {
  color: white;
  font-family: "Poppins-Bold", sans-serif;
  font-size: 13px;
}

.contentBox {
  position: relative;
  width: 100%;
  min-height: 90vh;
  z-index: 1;
  margin-top: 35px;
  padding-top: 15px;
  padding-bottom: 35px;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.16);
    z-index: -1;
  }
}

.mainTableTitle {
  color: white;
  font-size: 27px;
  margin-top: 10px;
  font-family: "Poppins-Light", sans-serif;
  text-align: left;
  margin-left: 40px;
  margin-bottom: 50px;
}

.addToCartButton {
  padding: 0;
  width: 120px;
  height: 18px;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: center;
}

.bookedStatus {
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-family: "Poppins-Light", sans-serif;
  line-height: 18px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}

.addToCartIcon {
  height: 100%;
}

.tableUnderline {
  height: 2px;
  background-color: white;
  margin-left: 40px;
  margin-right: 55px;
}

.lowerSection {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}

.scheduleSection {
  position: relative;
  padding: 20px 15px 15px 40px;
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.scheduleTitle {
  position: absolute;
  color: white;
  font-size: 16px;
  text-align: left;
  font-family: "Poppins-Bold", sans-serif;
}

.dayTableOuterContainer {
  margin-top: 10px;
}

.monthTableWrapper {
  width: 100%;
  margin-top: 35px;
}

.dayTableWrapper {
  width: 100%;
  height: 250px;
  margin-top: 5px;
  overflow-y: auto;
}

.tableTitle {
  color: white;
  padding-left: 3px;
  font-size: 14px;
  margin-bottom: 7px;
  text-align: left;
  font-family: "Poppins-Light", sans-serif;
}

table.monthTable,
table.dayTable,
{
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 12px;

  th.headerCell,
  td.bodyCell {
    position: relative;
    border: 1px solid rgb(208, 208, 208);
    padding: 8px;
    text-align: center;
    font-size: 14px !important;
    font-family: "Poppins-regular", sans-serif;
  }

  th.headerCell {
    background-color: white;
    color: #FF3466;
    position: sticky;
    top: 0;
  }
}

.timezoneNote {
  font-size: 11px;
  color: white;
  text-align: right;
  font-family: "Poppins-Light", sans-serif;
}

.propertyCard {
  min-width: 487px;
  height: 335px;
  overflow: hidden;
  background: linear-gradient(20deg, #6d3fa8, #b30e3f);
  color: white;
  font-family: Poppins-Regular, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 11px white solid;
  border-radius: 10px;
  margin: 0 55px 0 15px;
  padding: 12px;
}

.cardContent {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.leftSide {
  width: 180px;
  background-color: #FFFFFF1A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  line-height: 40px;
  font-size: 22px;
  text-transform: uppercase;
}

.sportText {
  font-family: Poppins-Light, sans-serif;
  position: relative;
  max-width: 170px;
  overflow-wrap: break-word;

  &:after {
    content: "";
    width: 160px;
    height: 1px;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.propertyText {
  max-width: 170px;
  overflow-wrap: break-word;
  font-family: Poppins-SemiBold, sans-serif;
}

.rightSide {
  width: 100%;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}

.propertyInfo {
  margin-top: 30px;
  font-size: 14px;
  line-height: 23px;
  text-align: left;
  font-family: Poppins-SemiBold, sans-serif;
}

.propertyStats {
  width: 100%;
  margin-top: 20px;
  text-align: left;
  font-size: 13px;
  font-family: Poppins-Light, sans-serif;
}

.propertyStatsItem {
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
  font-family: "Poppins-Light", sans-serif;
}

.backButton {
  @include backButton;
}
