@import '../../../../../../../../src/mixins';
@import '../../../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  @include tableScroll;

  ::-webkit-scrollbar-track {
    margin-top: 50px;
  }
}

.bissTypeControls {
  position: relative;
  padding-top: 16px;
}

.form {
  @include formScrollable;
}

.contentWrapper {
  @include reportingSatelliteForm;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .inputsWrapper {
    height: 100%;
    width: 100%;
    padding: 16px 27px 30px 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    label {
      font-size: 16px;
      width: 100%;
      position: relative;
      margin-top: 27px;

      & > input {
        margin-top: 8px;
      }

      .units {
        position: absolute;
        top: 55%;
        left: 262px;
        font-size: 14px;
        color: #8D8D8D;
        font-style: italic;
      }
    }

    input {
      @include defaultInput;
    }

    .inputShort {
      width: 260px;
    }

    .error {
      bottom: -15px;
      color: #ff3466;
      font-size: 11px;
      position: absolute;
      right: 0;
    }
  }
}

.dateInputSection {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 18px;
}

.buttons {
  @include actionButtons;
}

.timeFormat {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 14px;
  font-style: italic;
  color: #808080;
}

.file {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 14px;
  line-height: 16px;
  padding-top: 5px;
}

.fileUrl {
  cursor: pointer;
  text-overflow: ellipsis;

  &:hover {
    color: $brightColor;
  }
}

.uploadWrapper {
  margin: 25px auto;
}
