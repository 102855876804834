@import "../../../src/variables.module";

.btn {
  width: 100%;
  height: 40px;
  font-family: Poppins-Regular, sans-serif;
  background-color: $brightColor;
  color: #FFFFFF;
  border: none;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  text-align: center;
  text-transform: capitalize;

  & > :hover {
    color: #FFFFFF;
  }
}
