.datePicker {
  position: relative;
  width: inherit;
  height: 100%;
  color: #525252;
  font-weight: 500;
  line-height: 21px;
  font-size: 12px;
}

.calendarIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  pointer-events: none;

  img {
    display: block;
    width: 16px;
    height: 16px;
  }
}
