@import "../../../../mixins";
@import "../../../../variables.module";

.page {
  @include basicBackground;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;

  .upperSection {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 20px;
  }

  .pageTitle {
    color: white;
    font-family: "ITC Avant Garde Bold", sans-serif;
    font-size: 32px;
    line-height: 40px;
  }

  .detailsSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-left: 40px;
    margin-bottom: 5px;
  }

  .mainContentWrapper {
    width: 100%;
    height: 68vh;
    background: rgba(0, 0, 0, 0.16);
    margin-top: 20px;
    padding: 27px 0 45px 0;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .topSpace {
    margin-top: 75px;
  }
}

.backButton {
  @include backButton;
}

.recordInfo {
  width: 50%;
  padding: 0 10px;
}

.recordInfoItem {
  @include selectedRecordDetailsLine;
}

.searchInput {
  width: 512px;
  margin-right: 42px;
  margin-left: auto;
}

@media (max-width: 1350px) {
  .searchInput {
    width: 350px;
  }
}

@media (max-width: 1450px) or (max-height: 800px) {
  .recordInfoItem {
    font-size: 21px;
    line-height: 34px;
  }
}

@media (min-height: 900px) {
  .mainContentWrapper {
    height: 70vh;
  }
}
