@import '../../../../../../../../../mixins';
@import '../../../../../../../../../variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;
}

.form {
  width: 540px;
  position: fixed;
  max-height: 85%;
  color: #161616;
  background-color: #F4F4F4;
  font-family: "Poppins-Regular", sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
}

.contentWrapper {
  padding: 16px 16px 54px;
}

.titleWrapper {
  display: flex;
  justify-content: center;

  & > h6 {
    @include formTitle;
  }
}

.buttons {
  @include actionButtons;
}

.inpWrapper {
  display: flex;
  width: 100%;
  margin-top: 24px;
}

.label {
  display: block;
  font-size: 12px;
  width: 100%;
  position: relative;
}

.inputInner {
  width: 100%;
  position: relative;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formErrorBlock {
  @include formErrorBlock;
}

.checkboxes {
  margin-top: 10px;
  @include checkboxes;
}

.pageVisibilityText {
  margin-top: 10px;
}
