@import '../../../../../../../../src/mixins';
@import '../../../../../../../../src/variables.module';

.uploadLabel {
  width: 100%;
  height: 200px;
  padding: 21px 20px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed #8D8D8D;
  font-size: 14px;
  font-weight: bold;
  color: $brightColor;
  transition: 0.1s all ease-in-out;
  cursor: pointer;

  img {
    width: 25%;
  }

  &:focus,
  &:hover,
  &:active {
    border-radius: 0;
    border: 2px solid $brightColor;
    outline: none;
    background-color: #E8E8E8;
  }
}

.uploadTextSection {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100%;
  line-height: 20px;
  margin-left: 25px;

  & p {
    width: 100% !important;
  }
}

.formTitle {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.uploadTitle {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 12px;
  color: #FF3466;
  font-weight: bold;
}

.uploadDescription {
  color: #808080;
  margin-top: auto;
  font-style: italic;
  font-weight: initial;
  line-height: 25px;
}

.uploadInput {
  display: none !important;
}
