@import "src/mixins.scss";

.pageWrapper {
  @include basicBackground;
  padding-bottom: 0;
}

.pageContainer {
  @include container;
}

.tableWrapper {
  @include tableScroll;
  margin: 0 33px;
}

.searchInputInner {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 40px;
}

.searchInput {
  @include searchInput;
  width: 512px;
}
