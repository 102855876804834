@import "../../../../../../src/mixins";
@import "../../../../../../src/variables.module";

.tableOverlay {
  background: rgba(0, 0, 0, 0.16);
  min-width: 48.5%;
  max-width: 48.5%;
  height: 100%;
  padding-bottom: 15px;
}

.tableTitle {
  font-size: 26px;
  line-height: 32px;
  color: #FFFFFF;
  padding: 22px 0 53px 37px;
}

.tableWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 25px 25px 30px;
  max-height: 56vh;
  scrollbar-gutter: stable;

  .cellWithCheckbox {
    display: flex;
    align-items: center;
    width: 100%;

    .checkbox {
      margin-right: 10px;
    }
  }
}

.assignmentTable {
  @include table;
  table-layout: fixed !important;
}

.tableScroll {
  @include tableScroll;

  ::-webkit-scrollbar-track {
    margin-top: 67px;
  }

}

.noSpaceCell {
  white-space: nowrap;
}

.centredCell {
  text-align: center !important
}

@media (max-height: 850px) {
  .tableWrapper {
    max-height: 51vh;
  }
}

