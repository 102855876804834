@import "../../../../../mixins";

.pageInner {
  @include basicBackground;
}

.controlOverlay {
  background: rgba(0, 0, 0, 0.16);
  padding: 26px 33px;
}

.controlInner {
  display: flex;
  padding-bottom: 5px;
  font-size: 26px;
  line-height: 32px;
  justify-content: space-between;
  align-items: center;
}

.linksInner {
  white-space: nowrap;

  & > a:hover {
    background-color: inherit;
  }
}

.notActiveLink {
  @include notActiveLink;
  margin: 0 30px 0 0;
}

.activeLink {
  @include activeLink;
  margin: 0 30px 0 0;
}

.searchWrapper {
  width: 512px;
}

@media (max-width: 1350px) {
  .linksInner {
    display: flex;
    flex-direction: column;
  }
}
