@import '../../../../../src/mixins';
@import '../../../../../src/variables.module';

.alertPopup {
  width: 380px;
  min-height: 250px;
  padding-top: 5px;
  background: #EBEBEB;
  font-family: "Poppins-Regular", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: fixed;
  right: -400px;
  bottom: 5px;
  z-index: 1000;
  opacity: 0;
  transition: 0.3s all ease-in-out;

  &.show {
    opacity: 1;
    right: 5px;
  }

  .closeButton {
    width: 27px;
    height: 27px;
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    position: relative;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    font-family: "Poppins-Regular", sans-serif;
    font-weight: 600;
    color: black;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      height: 2px;
      width: 200px;
      background: $brightColor;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .decor {
    height: 50px;
    width: 50px;
    margin-top: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: $brightColor;
  }

  .bellIconBig {
    width: 30px;
    height: 30px;
  }

  .content {
    margin-top: 23px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    padding: 0 60px;
  }

  .text {
    font-size: 16px;
    margin-bottom: 15px;
    color: $brightColor;
    font-weight: 600;
  }

  .textLarge {
    font-size: 18px;
    color: black;
    line-height: 25px;
    font-weight: 600;
  }

  .buttons {
    @include actionButtons;
    justify-content: center;

    & > button {
      height: 28px;
      font-size: 14px;

      &:nth-child(2) {
        margin-left: auto;
      }
    }

    margin-top: auto;

    .cancelButton, .submitButton {
      cursor: pointer;
    }
  }
}
