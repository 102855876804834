@import '../../../../../../src/mixins';
@import '../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  @include tableScroll;

  ::-webkit-scrollbar-track {
    margin-top: 50px;
  }
  z-index: 1000;

  .form {
    @include formScrollable;

    .title {
      @include formTitleUnderLined;
    }

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      padding: 15px 27px 40px 27px;
      overflow-y: auto;
      overflow-x: hidden;

      .inputInner {
        width: 100%;
        margin-bottom: 28px;
      }

      .label {
        @include label
      }
    }
  }

  .buttons {
    @include actionButtons;

    button {
      cursor: pointer;
    }
  }
}

.formErrorBlock {
  @include formErrorBlock;
}

.dateInputSection {
  margin-bottom: 28px;
}

.calendar {
  font-size: 16px;
  margin-top: 28px;
  position: relative;

  .calendarInputWrapper {
    margin-top: 8px;
    height: 48px;
  }
}
