@import '../../../../../../mixins';
@import '../../../../../../variables.module';

.overlay {
  @include modalOverlay;
  @include tableScroll;

  .label {
    @include label;
    height: 100%;
  }

  .form {
    @include formScrollable;
    max-height: 80%;

    .title {
      @include formTitleUnderLined;
    }

    .content {
      overflow-y: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 32px;
      padding:  10px 27px 30px 27px;
    }

    .inputs {
      display: flex;
      justify-content: space-between;
    }

    .inputInner {
      margin-top: 25px;
    }

    .selectSection {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .ingestMethodInfo {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      margin-top: 27px;
      font-family: "Poppins-Bold", sans-serif;

      .infoText {
        font-size: 16px;
        font-family: inherit;

        .methodName {
          font-size: 16px;
          padding-left: 5px;
          color: #FF3466;
          font-family: inherit;
        }
      }

      .methodControls {
        display: flex;
        column-gap: 10px;
        font-size: 12px;
        font-family: inherit;

        p {
          cursor: pointer;
        }
      }
    }

    .uploadSection {
      margin-bottom: 30px;

      .uploadTitle {
        color: #2A2F35;
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      .uploadSubtitle {
        font-size: 14px;
        color: #525252;
        max-width: 256px;
      }

      .dropArea {
        max-width: 256px;
        height: 70px;
        border: 2px dashed #525252;
        margin-top: 10px;

        svg {
          display: none;
        }
      }

      .filesTable {
        font-size: 14px;
        font-family: "Poppins-Regular", sans-serif;

        .file {
          padding-top: 30px;
          display: flex;
          align-items: center;

          .fileName {
            max-width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 40px;
          }

          .closeIcon {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  .buttons {
    @include actionButtons;
    position: sticky;
    background-color: #F4F4F4;
  }
}

.uploadLabel {
  @include uploadLabel;
}

.uploadTextSection {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 210px;
  height: 100%;
  line-height: 23px;
}

.uploadTitle {
  font-size: 20px;
  margin-top: 8px;
  margin-bottom: 15px;
  color: #FF3466;
  font-weight: bold;
}

.uploadDescription {
  color: #808080;
  margin-top: auto;
  font-style: italic;
  font-weight: initial;
  line-height: 25px;
}

.uploadInput {
  display: none;
}

.formErrorBlock {
  @include formErrorBlock
}
