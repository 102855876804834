@import '../../../../../../src/mixins';
@import '../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 512px;
    height: 600px;
    position: fixed;
    overflow-y: auto;
    color: #161616;
    background-color: #EBEBEB;
    z-index: 10;
    font-family: "Poppins-Regular", sans-serif;

    .formContent {
      padding-top: 38px;
    }

    .content {
      padding: 0 27px 30px 27px;

      .title {
        @include formTitleUnderLined;
      }

      .inputInner {
        width: 100%;
        margin-bottom: 28px;
      }

      .label {
        @include label
      }
    }
  }

  .buttons {
    @include actionButtons;

    button {
      cursor: pointer;
    }
  }
}

.formErrorBlock {
  @include formErrorBlock;
}

.dateInputSection {
  margin-bottom: 28px;
}

.calendar {
  font-size: 16px;
  margin-top: 28px;
  position: relative;

  .calendarInputWrapper {
    margin-top: 8px;
    height: 48px;
  }
}
