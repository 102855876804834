@import '../../../../../../../../mixins';

.wrapper {
  @include modalOverlay;
}

.tableInner {
  height: 590px;
  overflow: auto;
  width: 750px;
  position: relative;
  background: white;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;

  .tableBackground {
    background: #E3E3E3;
    padding: 30px;
    height: 495px;
    overflow: auto;

    table {
      border-bottom: 2px solid #EA315F;
      border-top: 2px solid #EA315F;

      th {
        color: #EA315F;
        padding: 8px;
      }

      td {
        color: black;
      }
    }
  }
}

.checkbox {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 2rem !important;
}

.closePopup {
  position: absolute;
  right: 0.5rem;
  top: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1000;
}

.title {
  @include issueFormTitle;
}

.editIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  right: 15px;
}

.buttons {
  @include actionButtons;
}
