@import '../../../src/mixins';
@import '../../../src/variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;

  .form {
    width: 560px;
    min-height: 418px;
    padding-top: 5px;
    background: #EBEBEB;
    font-family: "Poppins-Regular", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    .title {
      position: relative;
      height: 50px;
      font-size: 20px;
      line-height: 50px;
      font-family: "Poppins-Regular", sans-serif;
      font-weight: 600;
      color: black;
      text-align: center;

      &:after {
        content: "";
        position: absolute;
        height: 2px;
        width: 200px;
        background: $brightColor;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .decor {
      height: 155px;
      width: 155px;
      margin-top: 32px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: $brightColor;
    }

    .content {
      margin-top: 20px;
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
      padding: 0 60px;
    }

    .message {
      font-size: 20px;
      line-height: 32px;
      color: $brightColor;
      font-weight: 600;
    }

    .buttons {
      @include actionButtons;

      margin-top: auto;

      .cancelButton, .submitButton {
        cursor: pointer;
      }
    }
  }

  .formWide {
    width: 560px;
    height: 400px;
    padding: 0 24px 24px;
  }
}
