.downloadContainer {
  display: flex;
  height: 100%;
  border-left: 1px solid #525252;
  border-right: 1px solid #525252;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.csvBtn {
  display: flex;
  border: none;
  color: #FFFFFF;
  background-color: inherit;
  align-items: center;
  height: 100%;
  padding: 0 16px 0 16px;
  font-family: Poppins-SemiBold, sans-serif;

  & img {
    width: 16px;
    height: 16px;
    margin-left: 28px;
  }
}

.csvBtn:hover {
  cursor: pointer;
}
