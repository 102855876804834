@import '../../../../../../../mixins';
@import '../../../../../../../variables.module';

.overlay {
  @include modalOverlay;
  z-index: 90;
  @include tableScroll;
}

.bissTypeControls {
  position: relative;
  padding-top: 16px;
}

.form {
  @include ingestOptionFormSmall;
  width: 677px;
  height: fit-content;
  max-height: 85%;
  background-color: #EBEBEB;
  z-index: 10;
  font-family: "Poppins-Regular", sans-serif;
  position: relative;

  h1 {
    font-size: 20px;
    margin-bottom: 2px;
  }

  .checkboxGroup {
    display: flex;
    justify-content: space-between;
  }

  .calendar {
    font-size: 16px;
    margin-top: 10px;
    position: relative;
    width: 220px;

    .calendarInputWrapper {
      margin-top: 8px;
      height: 48px;
    }
  }

  .contentWrapper {
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 35px;
  }

  .ingestTabs {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    z-index: 10;

    .ingestTab {
      height: 48px;
      width: 50%;
      background: #FFFFFF;
      color: #B2B2B2;
      cursor: pointer;
      font-size: 20px;
      line-height: 48px;
      text-align: center;
      font-weight: bold;
      border: none;
    }

    .activeTab {
      position: relative;
      color: #000000;
      background: #EBEBEB;

      &:after {
        content: "";
        width: 65%;
        height: 2px;
        background-color: #EA315F;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .coverElement {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
  }

  label {
    display: block;
    font-size: 16px;
    width: 100%;
    position: relative;
    margin-top: 12px;

    & > input {
      margin-top: 8px;
      height: 48px;
    }

    .units {
      position: absolute;
      top: 55%;
      left: 262px;
      font-size: 14px;
      color: #8D8D8D;
      font-style: italic;
    }
  }

  input {
    @include defaultInput;
    height: 40px;
  }

  .inputsWrapper {
    height: 100%;
    width: 100%;
    padding: 16px 27px 30px 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .uploadLabel {
      width: 96%;
      height: 200px;
      margin: 25px auto;
      padding: 21px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px dashed #8D8D8D;
      font-size: 14px;
      font-weight: bold;
      color: $brightColor;
      transition: 0.1s all ease-in-out;

      img {
        width: 25%;
      }

      &:focus,
      &:hover,
      &:active {
        border-radius: 0;
        border: 2px solid $brightColor;
        outline: none;
      }

      &.dragActive {
        background-color: #E8E8E8;
      }

      &.uploaded {
        background-color: #E0E0E0;
      }
    }

    .uploadTextSection {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      width: 240px;
      height: 100%;
      line-height: 20px;
    }

    .uploadInner {
      margin-bottom: 24px;
    }
  }

  .formTitle {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
}

.dateInputSection {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 8px;
}

.buttons {
  @include actionButtons;
}

.timeFormat {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 14px;
  font-style: italic;
  color: #808080;
}

.file {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 14px;
  line-height: 16px;
  padding: 5px 0 0 0;
  cursor: default;
  background-color: inherit;
  border: none;
}

.fileUrl {
  cursor: pointer;
  text-overflow: ellipsis;

  &:hover {
    color: $brightColor;
  }
}

.errorMessage {
  position: absolute;
  bottom: 15px;
  right: 25px;

  margin-top: 5px;
  color: $brightColor;
  font-size: 11px;
}

.satelliteBtn {
  width: 150px;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 12px;
  border: none;
  background: #b9b9b9;
  cursor: pointer;
  height: 28px;
  margin-top: 5px;
  transition: 0.3s all;
  border-radius: 3px;

  &:hover {
    transition: 0.3s all;
    background: #FF3466;
  }
}

.satelliteBtnHandler {
  margin-top: 15px;
}

.backupCheckboxWrapper {
  position: relative;
  left: 79%;
  bottom: 0;

  label {
    font-size: 13px;
  }
}

.currentIndicator {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #FF3466;
  top: 18px;
  left: 70px;
  border-radius: 50%;
}

.error {
  margin-left: 80%;
  margin-top: 5px;
  color: red;
  font-size: 12px;
  width: max-content;
}
