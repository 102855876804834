.mainChartLegend {
  cursor: default;
  position: absolute;
  right: 0;
  top: 135px;
  max-height: 535px;
  overflow-y: auto;
  z-index: 1;

  .legendItem {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }

  .legendItemLine {
    display: inline-block;
    width: 35px;
    height: 3px;
    margin-right: 15px;
  }

  .legendItemName {
    display: inline-block;
    font-size: 12px;
    line-height: 17px;
    margin-right: 10px;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #FFFFFF;
  }
}

.legendTooltip {
  z-index: 1000;
}
