@import "../../variables.module";
@import "../../mixins";

.headerInner {
  background-color: #2B2A2B;
  position: fixed;
  top: 67px;
  left: 0;
  z-index: 10;
  width: 100%;
}

.pageContainer {
  @include container;
  border-top: 1px solid #3b3a3b;

  .pageHeader {

    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .path {
      position: relative;
      display: flex;
      color: white;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;

      & > div > img {
        width: 11px;
        height: 11px;
        margin-left: 10px;
        margin-right: 7px;
        cursor: pointer;
      }

      .componentsDropdown {
        position: absolute;
        top: 35px;
        left: 96px;
        box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
        z-index: 6;

        .activeComponent {
          background-color: #E8E8E8;
        }
      }

      .comboSubMenuDropdown {
        top: 35px;
        left: 396px;
        box-shadow: rgba(60, 64, 67, 0.1) 0 0 2px 0, rgba(60, 64, 67, 0.1) 0 0 6px 0;
      }

      .subMenuDropdown {
        top: 35px;
        left: 196px;
        box-shadow: rgba(60, 64, 67, 0.1) 0 0 2px 0, rgba(60, 64, 67, 0.1) 0 0 6px 0;
      }
    }
  }
}

.menuTitle {
  color: $brightColor;
}

.chevron {
  width: 17px;
  height: 17px;
  transition: 0.1s all ease-in-out;
}

.usersChevron {
  width: 12px;
  height: 12px;
  margin-right: 7px;
}

.chevronRight {
  transform: rotate(-90deg);
}

.chevronUp {
  transform: rotate(180deg);
}

.subTitleBtn {
  background: transparent;
  outline: none;
  border: none;
  color: white;
  position: relative;
  cursor: pointer;
}

.componentItem {
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F4F4F4;
  background-color: white;
  color: #161616;
  padding: 0 18px 0 15px;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
  position: relative;

  &:hover {
    background-color: #E8E8E8;
    color: #161616;
  }
}

.separator {
  margin: 0 10px;
  color: #FFFFFF;

  &:hover {
    color: #FFFFFF;
  }
}

.chevronInner {
  display: inline-block;

  & > img {
    width: 11px;
    height: 11px;
    margin-right: 7px;
    cursor: pointer;
  }
}

.headerBtn {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.childrenTitle {
  color: white;
}
