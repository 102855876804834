@import "../../../../variables.module";

.cmsHeader {
  height: 67px;
  width: 100%;
  position: fixed;
  z-index: 5;
  background-color: #161616;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 19px;
  font-weight: 400;
}

.menuButton {
  width: 67px;
  height: 100%;
  cursor: pointer;
}

.headerTitle {
  position: absolute;
  left: 4%;
  padding-left: 15px;

  .pinkTitle {
    color: #EA315F;
  }

  & span {
    font-weight: 600;
  }
}

.actionsMenu {
  margin-right: 20px;
  border-right: 1px #707070 solid;
  display: flex;
  align-items: baseline;
  padding-right: 17px;
  gap: 15px;
}

.actionsMenuButton {
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.additionalCartBtn {
  position: relative;
  background: transparent;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 0;
}

.actionsMenuIcon {
  width: 30px;
  height: 30px;
}

.headerNotification {
  position: relative;
  width: 25px;
  height: 25px;
  margin-top: 5px;
  margin-left: auto;

  & img {
    width: 100%;
    height: 100%;
  }

  & div {
    width: 27px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    padding-top: 5px;
    background-color: $brightColor;
    color: white;
    font-size: 11px;
    position: absolute;
    top: -5px;
    left: 12px;
  }
}

.userInfo {
  .userName {
    padding-top: 10px;
    margin-left: 22px;
  }

  .organization {
    padding-top: 5px;
    text-align: right;
    font-size: 12px;
  }
}

.link {
  text-decoration: inherit;
  font-size: inherit;
  padding-bottom: 10px;
  color: inherit;
  transition: .3s;
  cursor: pointer;
  padding-left: 6px;
}

.link:hover {
  color: $brightColor;
}

span:hover {
  color: $brightColor;
}

.rightCorner {
  width: 62.5px;
  height: 100%;
  margin-left: 25px;
  background-color: transparent;
  border-left: 1px solid #3b3a3b;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: white;
  }
}

.cartIcon {
  width: 30px;
  height: 30px;
}

.cartItem {
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -5px;
  width: 17px;
  height: 17px;
  background: $brightColor;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
  }
}
