@import "src/mixins";

.infiniteScrollContainer {
  @include tableScroll;

  ::-webkit-scrollbar-track {
    margin-top: 60px;
  }
}

.userTableTooltip {
  background-color: #FF3466 !important;
  padding: 5px 7px !important;
  font-size: 12px;
  opacity: 1;
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 1000;
}

.textCentre {
  text-align: center !important;
}

.actionIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  outline: none;
  border: none;
}

.sorting {
  cursor: pointer;

  &:hover {
    background-color: #cbcaca;
    transition: 0.2s ease-in-out;
  }
}

.ascendingWrapper {
  display: inline-block;
  padding-left: 3px;
  position: absolute;
}

.tableButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tableButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
}

.descrIcon, .auditIcon {
  width: 100%;
  height: 100%;
}
