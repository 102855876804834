@import '../../../../../../../src/mixins';
@import '../../../../../../../src/variables.module';

.tableWrap {
  width: 100%;
  max-height: 54vh;
  overflow-y: auto;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
}

::-webkit-scrollbar-thumb:hover {
  background: #888888;
}

td.ingestCell {
  text-align: left;

  .ingestBtn {
    outline: none;
    background: transparent;
    border: 0.5px solid rgba(94, 94, 94, 0.51);
    font-family: "Poppins-Regular", sans-serif;
    color: #EA315F;
    cursor: pointer;
  }
}

.ingestDropdownIcon {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 13px;
  left: 10px;
  cursor: pointer;
}

.ingestDropdown {
  position: absolute;
  top: 45px;
  left: 40px;
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
  z-index: 1;

  .ingestItem {
    width: 145px;
    height: 39px;
    border-bottom: 1px solid #F4F4F4;
    background-color: white;
    color: #161616;
    padding-left: 15px;
    line-height: 40px;
    cursor: pointer;
    transition: 0.1s all ease-in-out;

    &:hover {
      background-color: #E8E8E8;
    }
  }
}

.upperPosition {
  top: -115px;
}

.sidePosition {
  top: -50px;
}

td.ingestCellDisabled {
  opacity: 0.4;

  & > img {
    cursor: default;
  }
}

.chevronUp {
  transform: rotate(180deg);
}
