@import '../../../../../../../../../mixins';

.formContent {
  margin: 0 16px 16px;
  padding: 0 16px 16px;
  height: 100%;
}

.formSubTitle {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  color: #525252;
  padding-bottom: 8px;

  & span {
    color: $brightColor;
  }
}

.formErrorBlock {
  @include formErrorBlock;
  position: relative;
  text-align: center;
  font-size: 16px;
  margin-bottom: -10px;
  font-weight: bold;
}

.oneInputContainer {
  display: flex;
  border: none;
  padding: 0;
  background-color: transparent;

  & > label {
    max-width: 100%;
  }
}

.uploadLabel {
  @include uploadLabel;
  height: 260px;
}

.uploadTextSection {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 400px;
  height: 100%;
  line-height: 23px;
}

.formTitle {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.uploadDescription {
  color: #808080;
  margin-top: auto;
  font-style: italic;
  font-weight: initial;
  line-height: 25px;
}

.uploadInput {
  display: none;
}
