@import "../../../../../variables.module";

.dropdownMenu {
  width: 120px;
  min-height: 60px;
  padding: 10px;
  font-family: Poppins-SemiBold, sans-serif;
  text-decoration: none;
  font-size: 15px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  color: #2A2F35;
  background-color: white;
  position: absolute;
  border: 1px solid #2A2F35;
  top: 3px;
  right: 4px;

  a {
    text-decoration: inherit;
    font-size: inherit;
    padding-bottom: 10px;
    color: inherit;
    transition: .3s;

    &:hover {
      color: $brightColor;
    }
  }

  a:last-child {
    padding: 0;
  }

  .link {
    text-decoration: inherit;
    font-size: inherit;
    padding-bottom: 10px;
    color: inherit;
    transition: .3s;
    cursor: pointer;
  }

  .link:hover {
    color: $brightColor;
  }

  span:hover {
    color: $brightColor;
  }
}

.logoutIcon {
  & > svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  & > svg:hover {
    color: #EA315F;
    cursor: pointer;
  }

  margin-bottom: 5px;
}
