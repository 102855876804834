.checkboxWrapper {
  display: inline-block;
}

.customCheckbox {
  display: none;
}

.customCheckbox + label {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 2px;
  border: 2px solid #FFFFFF;
  position: relative;
  background: transparent; /* Background color of the unchecked box */
  cursor: pointer;

  &.disabled {
    filter: opacity(0.5);
    cursor: default;
  }
}

.customCheckbox:checked + label::after {
  content: "";
  position: absolute;
  top: -9px;
  left: 6px;
  width: 7px;
  height: 17px;
  border: solid #EA315F;
  border-width: 0 3px 3px 0;
  transform: rotate(41deg);
}

