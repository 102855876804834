@import "../../../../mixins";

.donutChartLegend {
  position: absolute;
  top: 0;
  right: -38%;
  display: flex;
  justify-content: center;
  height: 180px;
  width: 130px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.16);
  }

  &::-webkit-scrollbar-thumb {
    background: #FF3466;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #cc3359;
  }

  .donutChartLegendItemsWrapper {
    width: 100px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
  }

  .legendItem {
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    padding-right: 10px;
    margin-top: 10px;

    & > span {
      font-size: 12px;
      color: white;
      width: 81px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .legendItemBox {
      width: 12px;
      height: 12px;
      margin-right: 7px;
    }
  }
}
