@import "src/mixins";
@import "src/variables.module";

.pageWrapper {
  padding: 20px 40px 40px;
}

.pageContainer {
  @include container;
}

.title {
  @include pageTitle;
  padding: 28px 0;
}

.backButton {
  height: 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: inherit;
  font-size: 18px;
  border: none;
  cursor: pointer;
  color: #FFFFFF;

  & > svg {
    margin-right: 10px;
    color: #FFFFFF;
  }

  &:hover {
    color: $brightColor;

    & > svg {
      color: $brightColor;
    }
  }
}

.tableOverlay {
  background-color: rgba(0, 0, 0, 0.16);
  @include container;
  padding: 0 33px 27px;
}

.tableInner {
  border-top: 2px solid white;
  @include tableScroll;
}

.searchInner {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: 2px solid white;
  padding: 26px 0;
}

.addBtnInner {
  display: flex;
  justify-content: flex-end;
  padding: 24px 0;
}

.addUserBtn {
  display: flex;
  justify-content: end;
  margin: 24px 0;
}

.page{
  padding-bottom: 40px;
}

@media (max-width: 1350px) {
  .optionsAdjusted {
    top: -188px;
  }

  .paddingTop {
    padding-top: 0;
  }
}
