@import "../../../../../src/mixins";
@import "../../../../../src/variables.module";

.pageInner {
  @include basicBackground;
}

.pageContainer {
  @include container;
  padding: 20px 0 60px;
}

.title {
  @include pageTitle;
}

.tilesInner {
  background-color: rgba(0, 0, 0, .16);
  margin-top: 30px;
  padding: 60px 34px;
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
  padding-right: 10px;
  max-height: 56vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.tableScroll {
  @include tableScroll;
}

.tileWrapper {
  background-color: hsla(0, 0%, 100%, .2);
  height: 87px;
  border: none;
  cursor: pointer;
}

.tileContent {
  padding: 0 18px 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 22px;
  font-family: 'Poppins-Light', sans-serif;
}

.logo {
  max-width: 100px;
  max-height: 70px;
}
