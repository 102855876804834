@import '../../../../../../mixins';
@import '../../../../../../variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;
}

.form {
  @include ingestOptionFormSmall;
  width: 512px;

  .titleInner {
    display: flex;
    justify-content: center;

    .title {
      @include formTitle
    }
  }

  .method {
    font-size: 16px;
    font-family: "Poppins-Bold", sans-serif;
    color: #EA315F;
    margin-top: 30px;
  }

  label {
    margin-top: 25px;
  }

  input {
    height: 48px;
  }
}
