@import '../../../../../../../../src/mixins';
@import '../../../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  @include tableScroll;

  ::-webkit-scrollbar-track {
    margin-top: 50px;
  }
}

.form {
  @include formScrollable;
}

.editFormTitle {
  @include formTitleUnderLined;
  position: absolute;
  top: 0;
  z-index: 10;
  background-color: #EBEBEB;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .inputsWrapper {
    width: 100%;
    margin-top: 55px;
    padding: 0 11px 10px 27px;
  }

  label {
    display: block;
    margin-top: 28px;
    font-size: 16px;
    width: 100%;
    position: relative;

    & > input {
      margin-top: 8px;
      height: 48px;
    }
  }

  .error {
    position: absolute;
    bottom: -20px;
    right: 0;
    font-size: 11px;
    color: $brightColor;
  }

  .dateSection {
    display: flex;
    position: relative;
    margin-top: 28px;
    width: 100%;
    justify-content: space-between;
  }

  .calendar {
    position: relative;
    font-size: 16px;
    width: 226px;

    .calendarInputWrapper {
      & input {
        margin-top: 8px;
        @include defaultInput;
      }
    }
  }

  .checkBoxInputsSection {
    display: flex;
    width: 100%;
    height: 60px;
    margin-top: 41px;
    justify-content: space-between;
  }

  .timesFormatMessage {
    position: absolute;
    bottom: -30px;
    right: 0;
    font-style: italic;
    color: #808080;
    font-size: 13px;
  }

  .unknownTeamField {
    position: relative;
    @include customCheckbox;
    width: 50%;

    & > input {
      position: absolute;
      top: -1px;
      padding: 0;

      &:disabled {
        background-color: #a8a8a8;
      }
    }

    & label {
      font-size: 16px;
      margin: 0 0 0 32px;
      line-height: 20px;
    }
  }
}

.buttons {
  @include actionButtons;
}

.error {
  position: absolute;
  font-size: 11px;
  line-height: 16px;
  color: $brightColor;
  right: 0;
  bottom: -18px;
}

.attentionText {
  font-size: 11px;
  line-height: 16px;
  color: $brightColor;
}
