.unfoldedUpper {
  z-index: 2;

  .optionsDropdown {
    width: 200px;
  }
}

.donutChart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;

  h6 {
    font-size: 28px;
    font-family: "Poppins-Light", sans-serif;
    text-transform: capitalize;
  }
}

.leftSideModeButtons {
  height: 32px;
  margin-right: auto;
  display: flex;
  align-items: flex-start;

  .leftBtn {
    outline: none;
    border: 1px solid #FFFFFF;
    border-radius: 13px 0 0 13px;
    width: 68px;
    height: 33px;
    color: #FFFFFF;
    background: #FF3466;
    cursor: pointer;
  }

  .rightBtn {
    outline: none;
    border: 1px solid #FFFFFF;
    border-radius: 0 13px 13px 0;
    width: 68px;
    height: 33px;
    color: #FFFFFF;
    background: #DADADA;
    cursor: pointer;
  }
}

.noData {
  display: flex;
  justify-content: center;
  width: 100%
}
