@import '../../../../../../../src/mixins';
@import '../../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  @include tableScroll;
}

.bissTypeControls {
  position: relative;
  padding-top: 16px;
}

.form {
  @include reportingSatelliteForm;
  @include formScrollable;
  max-height: 80%;

  .title {
    @include formTitleUnderLined;
  }

  .inputsWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px 27px 55px 27px;
    justify-content: space-between;
    position: relative;

    .uploadLabel {
      @include uploadLabel;
    }

    .uploadTextSection {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      width: 210px;
      height: 100%;
      line-height: 23px;
    }

    .formTitle {
      font-size: 20px;
      font-weight: bold;
      color: black;
    }

    .uploadTitle {
      font-size: 20px;
      margin-top: 8px;
      margin-bottom: 15px;
      color: #FF3466;
      font-weight: bold;
    }

    .uploadDescription {
      color: #808080;
      margin-top: auto;
      font-style: italic;
      font-weight: initial;
      line-height: 25px;
    }

    .uploadInput {
      display: none;
    }

    label {
      display: block;
      font-size: 16px;
      width: 100%;
      position: relative;
      margin-top: 27px;

      & > input {
        margin-top: 8px;
        height: 48px;
      }

      .units {
        position: absolute;
        top: 55%;
        left: 262px;
        font-size: 14px;
        color: #8D8D8D;
        font-style: italic;
      }
    }

    input {
      @include defaultInput;
    }

    .inputShort {
      width: 260px;
    }

    .error {
      bottom: -15px;
      color: #ff3466;
      font-size: 11px;
      position: absolute;
      right: 0;
    }
  }
}

.dateInputSection {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 8px;
}

.buttons {
  @include actionButtons;
}

.timeFormat {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 14px;
  font-style: italic;
  color: #808080;
}

.fileUrl {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px;
  padding: 8px 0;

  &:hover {
    color: $brightColor;
  }
}

.dateSection {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}




