@import '../../../../../../../../mixins';
@import '../../../../../../../../variables.module';

.overlay {
  @include modalOverlay;
  z-index: 10000 !important;

  .formContainer {
    width: 720px;
    max-height: 85%;
    position: fixed;
    color: #161616;
    background-color: #EBEBEB;
    font-family: "Poppins-Regular", sans-serif;
    overflow-y: auto;
    z-index: 1000;
  }

  .closeButtonWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 5px 8px 0 0;

    & > svg {
      cursor: pointer;
      width: 30px;
      height: 30px;
      color: #161616;
    }
  }

  .titleWrapper {
    display: flex;
    justify-content: center;

    & > h6 {
      @include formTitle;
    }
  }

  .formContent {
    margin: 0 16px 16px;
    padding: 0 16px 16px;
    height: 100%;
  }

  .contractDateSection {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 20px;
  }

  .datePickerInner {
    width: 308px;
    z-index: 100;
  }

  .stepsWrapper {
    padding: 20px 16px 30px;
    margin: 0 16px;
  }

  .oneInputContainer {
    display: flex;

    & > label {
      max-width: 100%;
    }
  }

  .twoInputsContainer {
    display: flex;
    justify-content: space-between;
    gap: 32px;
  }

  .schedulingTextarea {
    width: 100%;
    margin-top: 8px;
    border: none;
    min-height: 100px;
    padding: 8px;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 25px;

    &:focus {
      border: 3px solid $brightColor;
      border-radius: 0;
      outline: none;
    }
  }

  .label {
    font-size: 12px;
    width: 100%;
    color: #525252;
    margin-bottom: 27px;

    & span {
      color: $brightColor;
    }

    & > div {
      padding-top: 10px;
    }
  }

  .selectLabel {
    font-size: 12px;
    width: 100%;
    color: #525252;
    padding-bottom: 27px;

    & span {
      color: $brightColor;
    }
  }

  .labelWidth {
    max-width: 310px;
  }

  .formErrorBlock {
    @include formErrorBlock;
    position: relative;
    text-align: left;
  }

  .formError {
    color: #FF3466;
    padding-top: 5px;
  }

  .formSubTitle {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 12px;
    line-height: 16px;
    color: #525252;
    padding-bottom: 8px;

    & span {
      color: $brightColor;
    }
  }

  .subtitle {
    padding-top: 3px;
  }

  .formSubTitleBorder {
    border-bottom: 1px solid #8D8D8D;
    margin-bottom: 21px;
  }

  .checkSection {
    margin-left: 5px;
  }

  .radioButtons {
    display: flex;

    & button {
      margin-right: 100px;
      border: none;
      padding: 0;
      background-color: inherit;
    }

    & button:last-child {
      margin: 0;
    }
  }

  .checkboxInner {
    margin-top: 27px;
  }

  .checkboxes {
    @include checkboxes;
  }

  .quickSelectionButtons {
    display: flex;
    justify-content: space-between;
    width: 170px;
    padding-left: 10px;

    .quickSelectionButton {
      margin-top: 12px;
      border: 1px #525252 solid;
      border-radius: 0;
      background-color: #F4F4F4;
      color: #161616;
      white-space: nowrap;
      font-family: "Poppins-Regular", sans-serif;
      font-size: 12px;
      padding: 4px 7px;
      cursor: pointer;
      transition: 0.2s all ease-in-out;

      &:hover {
        background-color: $brightColor;
        border: 1px $brightColor solid;
        color: white;
      }
    }
  }

  .coverElement {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
  }
}
