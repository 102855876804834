@import '../../../../../../mixins';
@import '../../../../../../variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;

  .btnContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .label {
    @include label;
    height: 100%;
  }

  .form {
    width: 512px;
    background: #F4F4F4;
    font-family: "Poppins-Regular", sans-serif;

    .titleInner {
      display: flex;
      justify-content: center;
    }

    .title {
      @include formTitle;
      text-align: center;
      padding-top: 5%;
    }

    .content {
      max-height: 70vh;
      padding: 16px;
      overflow: auto;
      @include appScroll;

      .headerBtn {
        width: 146px;

        button {
          height: 42px;
          font-size: 14px;
          text-transform: capitalize;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .inputs {
        display: flex;
        flex-direction: column;

        .inputInner {
          min-width: 377px;
          margin-top: 25px;
        }
      }

      .tableInner {
        margin-top: 20px;
        margin-bottom: 54px;
        max-height: 340px;
        overflow: auto;
        overflow-x: hidden;
        @include redTable;

        &::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: #FF3466;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5;
          border-radius: 0;
        }

        &::-webkit-scrollbar {
          width: 10px;
          background-color: #F5F5F5;
        }

        .controlCell {
          white-space: nowrap;
        }

        .actionIcon {
          cursor: pointer;
          font-size: 16px;

          &:first-child,
          &:nth-child(2) {
            margin-right: 12px;
          }
        }
      }
    }
  }

  .buttons {
    margin-top: 3px;
    @include actionButtons;
  }

  .tableButton {
    background: transparent;
    border: none;
    width: 15px;
    height: 15px;
    cursor: pointer;
    padding: 0;
  }

  .auditIcon {
    width: 100%;
    height: 100%;
    filter: invert(1);
  }

  .firstCellInner {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }

  .formErrorBlock {
    @include formErrorBlock;
    position: absolute;
    padding-top: 15px;
    padding-right: 15px;
  }

  .tableInfo {
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 10px;

    span {
      color: red;
      padding-left: 5px;
    }
  }

  .centredCell {
    text-align: center;
  }
}

.timeCell {
  white-space: nowrap;
}

.deleteIconBig {
  @include deleteIconBig;
}
