.unfoldedUpper {
  position: relative;
  z-index: 2;

  .leftSideModeButtons {
    height: 32px;
    margin-right: auto;
    display: flex;
    align-items: flex-start;

    .leftBtn {
      outline: none;
      border: 1px solid #FFFFFF;
      border-radius: 13px 0 0 13px;
      width: 68px;
      height: 33px;
      color: #FFFFFF;
      background: #DADADA;
      cursor: pointer;
    }

    .rightBtn {
      outline: none;
      border: 1px solid #FFFFFF;
      border-radius: 0 13px 13px 0;
      width: 68px;
      height: 33px;
      color: #FFFFFF;
      background: #FF3466;
      cursor: pointer;
    }
  }

  .optionsDropdown {
    width: 200px;
  }
}

.leftSideHeader {
  font-size: 16px;
  font-weight: bold;
  color: #161616;
  margin-top: 18px;
}

.topTen {
  .tableWrapper {
    margin-top: 24px;
    max-height: 450px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .topTenTable {
      width: 100%;

      & > thead > tr th {
        background-color: white;
        position: sticky;
        z-index: 1;
        font-size: 14px;
        padding: 15px 5px 11px 0;
        box-shadow: #E0E0E0 0 -1px 0 0 inset;
        min-width: 110px;
        text-align: left;
        font-weight: 700;
      }

      & > tbody > tr td {
        font-size: 12px;
        padding: 13px 5px 13px 0;
        border-bottom: 1px solid #F4F4F4;
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;

  h6 {
    font-size: 28px;
    font-family: "Poppins-Light", sans-serif;
    text-transform: capitalize;
  }
}
