@import '../../../src/mixins';
@import '../../../src/variables.module';

.tableContainer {
  @include tableScroll;
}

.tableWrapper {
  padding: 0 40px 50px 40px;
  position: relative;
  overflow-y: scroll;
}

.noTableScroll {
  overflow-y: visible;
}

.operatorTable {
  @include table;

  .greyCell {
    color: #B2B2B2;
  }
}

.clickable {
  cursor: pointer;
  transition: 0.2s all ease-in-out;

  &:hover {
    color: $brightColor;
  }
}

.favourite {
  display: inline-block;
  cursor: pointer;
}

.indicator {
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 12px;
  width: 17px;
  height: 17px;
  background: $brightColor;
}
