@import "../../../../../../../mixins";
@import "../../../../../../../variables.module";

.pageContainer {
  @include container;
  padding-bottom: 40px;
}

.tableOverlay {
  background: rgba(0, 0, 0, 0.16);
}

.tableInner {
  padding: 0 33px 24px;
  @include tableScroll;
}

.optionsContainer {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  border-top: 2px solid #E8E8E8;
  padding: 24px 0;
}

.usersWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-right: 10px;
  gap: 18px;
  max-height: 43vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.tileInner {
  border: none;
  background-color: transparent;
}

.userItem {
  height: 125px;
  padding: 15px 30px;
  font-size: 20px;
  font-family: Poppins-Light, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #FFFFFF;
  word-break: break-word;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;

  & > p {
    padding-bottom: 20px;
  }
}

.userItem:hover,
.userItem:active {
  transition: background-color 0.8s ease;
  background-color: rgba(160, 160, 160, 0.2);
  color: #FFFFFF;
}

.userName {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.userLogo {
  width: auto;
  height: 43px;
}

.buttonsContainer {
  margin-top: 15px;
  display: flex;
  gap: 0 20px;
}

.editButton {
  width: 77px;
  height: 25px;
  background-color: #393939;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.deleteButton {
  width: 77px;
  height: 25px;
  border: none;
  color: #FF3466;
  font-size: 12px;
  line-height: 21px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchMessage {
  width: 100%;
  text-align: center;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 18px;
}

.deleteIcon {
  @include deleteIconBig;
}

@media (max-width: 1500px) {
  .searchInput {
    width: 380px;
  }

  .usersWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

.defaultLogoWrapper {
  background: #FFFFFF;
  height: 43px;
}

@media (min-width: 1980px) {
  .usersWrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-height: 850px) {
  .usersWrapper {
    max-height: 33vh;
  }
}
