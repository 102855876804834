@import "../../../mixins.scss";

.calendarWrapper {
  display: flex;
  gap: 10px;
  margin-left: 60px;
}

.calendar {
  font-size: 18px;
  width: 220px;
  color: #FFFFFF;

  .calendarInputWrapper {
    & input {
      margin-top: 8px;
      @include defaultInput;
    }
  }
}

@media screen and (max-width: 1200px) {
  .calendar {
    width: 160px;
  }
}

.error {
  font-size: 11px;
  padding-top: 8px;
}
