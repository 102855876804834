.btn {
  outline: none;
  border: none;
  text-decoration: underline;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  cursor: pointer;
  color: #808080;
}

.logoWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .imgInner {
    position: relative;

    .closeIcon {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    img {
      width: 400px;
      height: 400px;
    }
  }
}
