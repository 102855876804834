.dragBoard {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0 15px;
}

.board {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45vh;
  overflow: auto;
  padding: 0 32px 16px;
  row-gap: 16px;
  background: rgba(0, 0, 0, .16);

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: #FF3466;
    min-height: 40px;
  }

  &::-webkit-scrollbar-track {
    background: #7e1d51;
  }

  &::-webkit-scrollbar:hover {
    background: #cc3359;
  }

  .boardItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background: #0000001A;
    color: white;
    font-size: 16px;
    cursor: grab;
    text-align: center;
    line-height: 40px;
    padding-left: 16px;
    padding-right: 12px;

    .flag-inner {
      display: flex;
      align-items: center;
      height: 24px;
      gap: 0 8px;

      .flag {
        width: 36px;
        height: 100%;
      }

      & svg {
        cursor: pointer;
      }
    }
  }
}

.instruction {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 25px;
  font-family: "Poppins-SemiBold", sans-serif;

  & > span {
    font-style: italic;
    font-family: "Poppins-Light", sans-serif;
    cursor: default;
  }

  & > span:hover {
    color: inherit;
  }
}

@media (max-height: 850px) {
  .board {
    height: 37vh;
  }
}