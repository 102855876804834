.form-group {
    display: block;
    margin-bottom: 15px;
}

.form-group input {
    display: none !important;
}

.form-group label {
    position: relative;
    cursor: pointer;
}

.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #161616;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 3px;
}

.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 5px;
    width: 6px;
    height: 10px;
    border: solid #FF3466;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
