@import "../../../../../../src/mixins";
@import "../../../../../../src/variables.module";

.tableOverlay {
  background: rgba(0, 0, 0, 0.16);
  min-width: 48.5%;
  max-width: 48.5%;
  height: 100%;
}

.tableTitle {
  font-size: 26px;
  line-height: 32px;
  color: #FFFFFF;
  padding: 22px 0 44px 37px;
}

.tableWrapper {
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 20px;
  padding-bottom: 30px;
  max-height: 56vh;
  overflow: auto;
  scrollbar-gutter: stable;
  overflow-x: hidden;
}

.tableScroll {
  @include tableScroll;
}

.pricingTable {
  @include table;
  table-layout: fixed !important;

  & th, td {
    width: 19%;
    padding-right: 0;
    font-size: 15px;
  }

  & th:nth-child(3),
  & th:nth-child(4),
  & th:nth-child(5) {
    text-align: right;
  }

  & td:nth-child(3),
  & td:nth-child(4),
  & td:nth-child(5) {
    text-align: right;
  }
}
