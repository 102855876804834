@import "../../../../../src/mixins";

.pageInner {
  @include basicBackground;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 28px;
  padding-bottom: 34px;
}

.title {
  @include pageTitle;
}

.addBtn {
  width: 232px;

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
  }
}

.tablesWrapper {
  display: flex;
  gap: 0 23px;
}

.deleteIconBig {
  @include deleteIconBig;
}
