@import '../../../../../../../../mixins';

.formLinksInner {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  cursor: default;
  justify-content: center;
}

.stepBtn {
  width: 130px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  background-color: #EBEBEB;

  .stepIndicator {
    display: flex;
    align-items: center;
    width: 100%;

    & > svg {
      color: #B2B2B2;
    }

    .stepLine {
      width: 100%;
      height: 2px;
      background: #B2B2B2;
    }
  }

  .stepText {
    padding-top: 3px;
    text-align: left;

    p {
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 14px;
      color: #B2B2B2;
      text-align: left;

      span {
        font-family: "Poppins-Bold", sans-serif;
        font-size: 14px;
        color: inherit;
        text-align: left;
      }
    }
  }
}

.stepBtn:last-child {
  width: auto;
}

.stepIndicator.activeTab {
  & > svg {
    color: #FF3466;
  }

  .stepLine {
    background: #FF3466;
  }
}

.stepText.activeTab {
  p {
    color: #FF3466;
  }
}

