
.textCentre {
  text-align: center !important;
}

.tableIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  color: #FFFFFF;
  background: transparent;
  outline: none;
  border: none;

  &:first-child {
    margin-right: 30px;
  }

  img {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
  }
}

.reportToolTip {
  z-index: 1000;
}

.ascendingWrapper {
  display: inline-block;
  padding-left: 3px;
  position: absolute;
}

.reportIcon {
  width: 20px !important;
  height: 21px !important;
}

.scrollableContainer {
  max-height: 50vh;

  table {
    th:nth-child(8) {
      text-align: center;
    }

    th:nth-child(9) {
      text-align: center;
    }
  }
}

.checkboxInner {
  display: flex;
  justify-content: center;
}

@media (max-height: 850px) {
  .scrollableContainer {
    max-height: 46vh;
  }
}
