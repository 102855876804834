@import "../../../mixins";
@import "../../../variables.module";

.checkoutPage {
  @include basicBackground;
}

.pageContainer {
  @include container;

  .upperSection {
    position: relative;
    padding-top: 20px;
  }

  .checkoutTitle {
    color: white;
    font-family: "ITC Avant Garde Bold", sans-serif;
    font-size: 32px;
    line-height: 40px;
  }

  .checkoutDescription {
    color: white;
    font-family: "Poppins-Light", sans-serif;
    font-size: 27px;
    margin-top: 15px;
  }

  .closeButton {
    position: absolute;
    top: 15px;
    right: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 35px;
    height: 35px;
  }

  .closeIcon {
    width: 100%;
    height: 100%;
    color: white;
  }

  .mainContentWrapper {
    width: 100%;
    height: 55vh;
    background: rgba(0, 0, 0, 0.16);
    margin-top: 5vh;
    padding: 26px 40px 45px 40px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .cartEmptyText {
    color: white;
  }

  .tableContainer {
    width: 100%;
    margin-top: 16px;

    ::-webkit-scrollbar {
      width: 16px;
    }

    ::-webkit-scrollbar-track {
      background: #7e1d51;
    }

    ::-webkit-scrollbar-thumb {
      background: #FF3466;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #cc3359;
    }
  }

  .tableWrapper {
    height: 43vh;
    padding: 0 40px 50px 40px;
    position: relative;
    overflow-y: scroll;
  }

  .deleteButton {
    position: absolute;
    background: transparent;
    border: none;
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    left: 25px;
  }

  .deleteIcon {
    width: 100%;
    height: 100%;
    color: #FFFFFF;
  }

  .checkoutFooter {
    display: flex;
    justify-content: end;

    .buttons {
      margin-top: 15px;
      @include actionButtons;
      width: 35%;
      justify-content: end;
    }
  }
}
