@import "../../../../../../src/mixins";
@import "../../../../../../src/variables.module";

.tableOverlay {
  background: rgba(0, 0, 0, 0.16);
  min-width: 48.5%;
  max-width: 48.5%;
  height: 100%;
}

.tableTitle {
  font-size: 26px;
  line-height: 32px;
  color: #FFFFFF;
  padding: 22px 0 44px 37px;
}

.tableWrapper {
  padding-left: 35px;
  padding-right: 20px;
  padding-bottom: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 56vh;
  scrollbar-gutter: stable;
}

.tableScroll {
  @include tableScroll;
}

.pricingTable {
  @include table;
  table-layout: fixed !important;

  & th, td {
    width: 33%;
    padding: 15px 3px;
  }
}
