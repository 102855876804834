@import "../../../../../../../mixins";

.pageContainer {
  @include container;
  padding-bottom: 40px;
}

.tableOverlay {
  background: rgba(0, 0, 0, 0.16);
  padding-bottom: 24px;
}

.tableInner {
  margin: 0 33px;
  @include tableScroll;
  border-top: 2px solid #E8E8E8;
}

.usersContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px;
  overflow-y: auto;
  height: 337px;
  margin-top: 18px;
  padding-right: 10px;
  align-items: start;
}

.tileButton {
  background-color: transparent;
  border: none;
}

.userItem {
  height: 100px;
  padding: 15px 30px;
  font-size: 20px;
  font-family: Poppins-Light, sans-serif;
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  word-break: break-word;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;

  & > p {
    padding-bottom: 20px;
  }
}

.userItem:hover,
.userItem:active {
  transition: background-color 0.8s ease;
  background-color: rgba(160, 160, 160, 0.2);
  color: #FFFFFF;
}

.userName {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.userLogo {
  width: 100px;
}

@media (min-width: 1980px) {
  .usersWrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
