@import "../../../mixins";
@import "../../../variables.module";

.dropdowns {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 407px;
  right: 0;
}

.label {
  display: block;
  font-size: 16px;
  width: 100%;
  position: relative;
  font-family: "Poppins-regular", sans-serif;
  color: #FFFFFF;
  line-height: 25px;
}

.resetFiltersButton {
  @include resetFilterButton;
}