.noDataComponent {
  width: 100%;
  height: 100%;
  padding: 50px 0;
  text-align: center;
  margin: 0 auto;

  .noDataComponentText {
    margin-top: 20px;
  }
}