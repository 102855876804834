@import '../../../../src/mixins';

.formOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: brightness(0.5);
  z-index: 100;

  .secondStepForm {
    width: 760px;
    background-color: #FFFFFF;

    .mainSection {
      height: 340px;
      position: relative;
      display: flex;

      &DevicesLogo {
        width: 50%;
        height: 100%;
        position: relative;

        & > img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 285px;
        }
      }

      &FormItems {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 15px;

        .formCloseButton {
          position: absolute;
          background: none;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          width: 20px;
          height: 20px;
          top: 17px;
          right: 17px;
          padding: 0;
          cursor: pointer;

          & > img {
            width: 100%;
            height: 100%;
          }
        }

        .formTitle {
          width: 335px;
          color: #161616;
          font-size: 17px;
          margin-top: 25px;
          font-family: "Poppins-regular", sans-serif;
        }

        .formSubtitle {
          width: 335px;
          color: #161616;
          font-size: 14px;
          line-height: 21px;
          font-family: "Poppins-regular", sans-serif;
          margin-top: 15px;
        }

        .inputLabel {
          position: relative;
          margin-top: 18px;
          color: #525252;
          display: block;
          font-size: 12px;
          font-family: "Poppins-regular", sans-serif;

          .verifyInput {
            @include defaultInput;
            width: 260px;
            background-color: #F4F4F4;
            height: 40px;
            font-size: 12px;
            font-family: "Poppins-regular", sans-serif;
            margin-top: 9px;
          }
        }
      }
    }

    .buttons {
      @include actionButtons;

      & > button {
        height: 55px;
        font-size: 16px;
      }
    }

    .formError {
      @include formErrorBlock;
      margin-top: 5px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #F4F4F4 inset !important;
}

