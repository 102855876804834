@import '../../../../../src/mixins';
@import '../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
}

.bookmarkForm {
  @include ingestOptionFormSmall;
  height: 250px;
}

.deleteButton {
  position: absolute;
  width: 30px;
  height: 28px;
  top: 20px;
  right: 20px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 3px;

  &:hover {
    background-color: #d9d9d9;
  }
}
