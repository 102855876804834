@import "../../../../../../src/mixins";
@import "../../../../../../src/variables.module";

.page {
  @include basicBackground;
  padding-top: 28px;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;

  .title {
    color: white;
    font-family: "ITC Avant Garde Bold", sans-serif;
    font-size: 32px;
  }

  .upperSection {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
    gap: 19px;
    margin-top: 45px;
    background: rgba(0, 0, 0, 0.16);
    padding: 28px 42px 19px 0;
  }
}

.noDataFoundStubUnderlay {
  width: 100%;
  background: rgba(0, 0, 0, 0.16);
}

.addButton {
  width: 232px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  background-color: $brightColor;
  line-height: 40px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-family: Poppins-Regular, sans-serif;
}

.search {
  width: 512px;
}

.deleteIconBig {
  @include deleteIconBig;
}
