@import '../../../../../../../src/mixins';

.overlay {
  @include modalOverlay;
}

.bookmarkList {
  position: relative;
  width: 512px;
  max-height: 436px;
  min-height: 200px;
  overflow: auto;
  background: #EBEBEB;
  padding-top: 10px;
  padding-left: 27px;
  padding-right: 27px;
  padding-bottom: 30px;
  @include appScroll
}

.closeButton {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
  outline: none;
  background: transparent;
  border: none;
  font-size: 20px;
}

.titleInner {
  display: flex;
  justify-content: center;
}

.title {
  @include formTitle;
  text-align: center;
  margin-bottom: 16px;
}

.list {
  display: flex;
  flex-direction: column;
  position: relative;
}

.listItem {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  margin-bottom: 16px;
  position: relative;
}

.listItemActive {
  border: 0.5px solid #EA315F;
}

.listContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px;
}

.bookmark {
  display: flex;
  align-items: center;

  .bookmarkName {
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.controls {
  & > button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
    color: #161616;
  }
}

.deleteIconBig {
  width: 105px;
  height: 105px;
}

.tooltip {
  background: #EA315F !important;
  width: fit-content !important;
  line-height: 24px !important;
  text-align: center !important;

}
