@import "../../../../../../src/mixins";

.pageInner {
  @include basicBackground;
  overflow-y: hidden;
}

.contentWrapper {
  background: rgba(0, 0, 0, 0.16);
  margin-top: 50px;
  padding: 26px 40px 45px 40px;
  max-height: 70vh;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  .title {
    font-size: 32px;
    font-family: "ITC Avant Garde Bold", sans-serif;
    color: #FFFFFF;
  }
}

.tableControls {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;

  .searchInput {
    @include searchInputPink;
    width: 512px;
  }
}

.reportingPage {
  padding-bottom: 50px;
}

.subHeader {
  background: #FFFFFF;
  margin-bottom: 48px;
}

.container {
  @include container;
  padding-bottom: 50px;
}

.pageContainer {
  @include container;
  display: flex;
  justify-content: space-between;
}

.topButtons {
  @include topButtons;
}

.searchInputInner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.ingestWrapper {
  display: flex;
  align-items: flex-end;
}

.ingestSelectInput {
  display: flex;
  margin-right: 20px;
}

.ingestSelectInputLabel {
  height: 32px;
  background: #FF3466;
  align-self: center;
  font-size: 14px;
  color: white;
  line-height: 32px;
  padding: 0 10px;
}

.range {
  width: 280px;
}
