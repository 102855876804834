.label {
  position: absolute;
  top: 0;
  height: 48px;
  width: 100%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  background: #EBEBEB;
  z-index: 20;

  &:after {
    content: "";
    width: 170px;
    height: 2px;
    background-color: #EA315F;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
