@import "../../../../../../../mixins";

.collapsedRow {
  & td {
    font-size: 16px;
    font-family: "Poppins-SemiBold", sans-serif;
    line-height: 25px;
  }

  & td:first-child {
    padding-left: 40px;
  }
}

.accordionCell {
  position: relative;
  cursor: pointer;
}

.tableChevron {
  @include tableChevron;
  left: 12px;
  top: 8px;
  color: $brightColor !important;
  transform: rotate(-90deg);
}

.tableChevronTop {
  transform: rotate(0deg);
  top: 10px;
}

.accordionRow {
  & > td {
    padding: 8px 4px 8px 10px;
    background: rgba(0, 0, 0, 0.1019607843);
  }
}

.buttonOTTInner {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}

.platformBtn {
  border: 1px solid $brightColor;
  color: white;
  background-color: transparent;
  padding: 3px 8px;
  cursor: pointer;
  font-size: 12px;
}

.platformBtnActive {
  background-color: $brightColor;
}
