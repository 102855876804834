@import "../../../../../../../variables.module";

.addUserBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins-Regular, sans-serif;
  width: 208px;
  height: 42px;
  background-color: $brightColor;
  color: #FFFFFF;
  border: none;
  font-size: 14px;
  line-height: 17px;
  padding: 17px 16px;
  cursor: pointer;

  & > svg {
    width: 12px;
    height: 12px;
  }

  & span {
    color: #FFFFFF;
  }
}
