@import "../../../../../../src/mixins";

.pageInner {
  @include basicBackground;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 28px;
  padding-bottom: 34px;
}

.title {
  @include pageTitle;
}

.controls {
  display: flex;
  min-width: 800px;
  align-items: flex-end;
  justify-content: flex-end;

  .controlBtnInner {
    display: flex;
    column-gap: 20px;
    padding-right: 40px;
  }

  .controlBtn {
    width: 100px;
    height: 40px;
    background: $brightColor;
    color: #FFFFFF;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-family: "Poppins-Regular", sans-serif;
  }

  .label {
    font-size: 18px;
    width: 100%;
    color: #FFFFFF;
  }

  .selectList {
    max-width: 329px;
    width: 100%;
    margin-right: 10px;
  }
}

.titlesWrapper {
  display: flex;
  justify-content: space-between;
  gap: 0 15px;
}

.tableTitleInner {
  background: rgba(0, 0, 0, 0.16);
  width: 50%;
  padding: 48px 32px 16px;

  .searchTitle {
    font-size: 26px;
    padding-bottom: 9px;
    width: 100%;
    color: #FFFFFF;
    line-height: 32px;
    cursor: default;
    font-family: "Poppins-SemiBold", sans-serif;
  }
}

.searchControl {
  width: 100%;
  position: relative;
  padding-bottom: 16px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.33);

  & input {
    padding-left: 30px;
    background: hsla(0, 0%, 100%, .2);
    color: #FFFFFF;
  }

  & input::placeholder {
    color: #bfbbbb;
    padding-left: 10px;
  }

  .clearBtn {
    position: absolute;
    right: 20px;
    bottom: 24px;
    width: 20px;
    height: 20px;
    color: #FFFFFF;
    font-family: "Poppins-Regular", sans-serif;
    outline: none;
    border-style: none;
    cursor: pointer;
    font-size: 12px;
    background: transparent;
  }

  .searchIcon {
    position: absolute;
    left: 8px;
    bottom: 29px;
    color: #bfbbbb;
  }
}

.tablesInner {
  display: flex;
  justify-content: space-between;
  gap: 0 15px;
}

.countriesWrapper {
  width: 67%;
}
