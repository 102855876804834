@import "../../../../variables.module";

.mainChart {
  padding: 16px 16px 30px 5px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: rgba(0, 0, 0, 0.16);
  position: relative;

  .trendButton {
    border: 1px solid #F4F4F4;
    border-radius: 4px;
    width: 31px;
    height: 30px;
    padding: 5px;
    cursor: pointer;
    background: #DADADA;
    color: #FFFFFF;

    &:hover {
      background-color: #F2F4F2;
    }

    &.trendButtonActive {
      color: white;
      background-color: #FF3466;
    }
  }

  .mainChartFilters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filters {
      display: flex;
      column-gap: 10px;
      align-items: flex-end;

      .zoomResetButton {
        display: block;
        border: none;
        height: 48px;
        background-color: $brightColor;
        width: 120px;
        font-size: 14px;
        color: white;
        font-family: "Poppins-Regular", sans-serif;
        cursor: pointer;
      }
    }

    .yaxisLabel {
      color: #FFFFFF;
      font-size: 28px;
      font-family: "Poppins-Light", sans-serif;
      padding-left: 37px;
    }
  }

  .trendSection {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .chartBody {
    position: relative;
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > svg {
      overflow: visible;
    }

  }
}

.optionsDropdown {
  width: 200px;
}
