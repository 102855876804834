@import "../../../../../mixins";

.pageInner {
  @include basicBackground;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;
}

.tableOverlay {
  background: rgba(0, 0, 0, 0.16);
}

.tableInner {
  padding: 0 40px 40px;
}

.options {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  padding: 20px 0 40px;
}

.searchInput {
  @include searchInput;
  width: 512px;
}

.publishingTable {
  @include table;

  border-collapse: separate;
  border-spacing: 0 5px;

  & td {
    padding: 8px 13px;
  }

  th:first-child {
    width: 20%;
  }

  th:last-child,
  td:last-child {
    width: 350px;
  }

  .lastCol {
    display: flex;
    justify-content: space-between;

    & > p:nth-child(2) {
      font-family: "Poppins-Light", sans-serif;
      font-style: italic;
      font-weight: 100;
      padding: 0 30px 0 50px;
    }

    .displayNone {
      display: none;
    }
  }
}
