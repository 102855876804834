@import "../../../../../../../../src/mixins";
@import "../../../../../../../../src/variables.module";

.tableContainer {
  width: 100%;
  background: rgba(0, 0, 0, 0.16);
  position: relative;
  padding: 0 27px 20px 40px;
}

.separateRow {
  background: rgba(0, 0, 0, 0.10);

  & > td {
    border-bottom: 6px solid transparent;
  }
}

td.checkboxCell {
  & > img {
    position: absolute;
    top: 5px;
    left: 18px;
  }
}

.userTableTooltip {
  background-color: #FF3466 !important;
  padding: 5px 7px !important;
  font-family: Poppins-Regular, sans-serif;
  font-size: 12px;
  opacity: 1;
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 1000;
}

.actionEnabled {
  width: 16px;
  height: 16px;
  color: #161616;
  cursor: pointer;
}

.actionDisabled {
  width: 16px;
  height: 16px;
  color: #D1D1D1;
  cursor: pointer;
}

.actions {
  display: flex;
  gap: 0 30px;

  & > button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;

    & > svg {
      color: white;
    }
  }
}

