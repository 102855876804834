@import '../../../../../../mixins';
@import '../../../../../../variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1100;

  .label {
    @include label;
    height: 100%;
  }

  .form {
    width: 512px;
    background: #F4F4F4;
    font-family: "Poppins-Regular", sans-serif;

    .content {
      max-height: 80vh;
      padding: 16px;
      overflow: auto;
      @include appScroll;

      .title {
        position: relative;
        text-align: center;
        font-size: 20px;
        color: #000000;
        font-family: "Poppins-SemiBold", sans-serif;
        padding-bottom: 9px;
        padding-top: 15px;
        margin-bottom: 15px;

        &:after {
          content: '';
          display: block;
          width: 30%;
          height: 2px;
          background-color: #EA315F;
          margin: 10px auto 0;
          bottom: 100px;
        }
      }

      .inputInner {
        width: 100%;
        margin-top: 25px;
      }

      .issueDescription {
        width: 100%;
        margin-top: 32px;

        .textarea {
          @include issueFormTextarea;
        }
      }

      .rangePicker {
        width: 250px;
        margin-top: 31px;
        position: relative;
      }
    }

    .buttons {
      @include actionButtons;
      margin-top: 10px;
    }
  }

  .formErrorBlock {
    @include formErrorBlock;
    position: relative;
    text-align: left;
  }
}

.unresolvedWrapper {
  position: absolute;
  right: 0;
  bottom: 48px;
  display: flex;
  justify-content: center;
}

.unresolvedCheckbox {
  margin-left: 5px;
  font-size: 13px;
}

.severityLabelWrapper {
  display: flex;
  align-items: center;
}

.tooltipSeverityWrapper {
  margin-left: 6px;
  max-width: 20px;
}

.tooltip {
  font-family: "Poppins-Regular", sans-serif;
}

.tooltipImg {
  display: block;
  max-width: 100%;
}
