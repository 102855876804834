@import "src/variables.module";

@mixin formErrorBlock {
  color: #FF3466;
  font-size: 11px;
  font-family: "Poppins-Regular", sans-serif;
  margin-top: 5px;
  text-align: center;
  position: absolute;
  width: 100%;
}

@mixin modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 100;
}

@mixin defaultInput {
  display: block;
  width: 100%;
  height: 48px;
  padding: 0 8px 0 10px;
  color: black;
  background-color: white;
  border: none;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins-Regular", sans-serif;
  outline: none;
  transition: .1s all;
  &:focus {
    border: 3px solid $brightColor;
    border-radius: 0;
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
    font-family: "Poppins-Regular", sans-serif;
    color: #8D8D8D;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }
}

@mixin actionButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 0 10px;
  position: sticky;
  bottom: 0;
  background-color: #EBEBEB;

  & button {
    position: relative;
    height: 48px;
    width: 50%;
    border: none;
    text-align: center;
    vertical-align: middle;
    font-family: Poppins-SemiBold, sans-serif;
    cursor: pointer;
    font-size: 16px;
  }

  .cancelButton {
    background-color: #B2B2B2;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #474747;
      color: white;
    }
  }

  .submitButton {
    background-color: $brightColor;
    color: white;
    border: none;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #EA315F;
    }

    &:disabled {
      background-color: #c6c6c6;
      color: #a8a8a8;
    }
  }

  .submitButtonDisabled {
    background-color: #C6C6C6;
    color: #A8A8A8;
    cursor: default;

    &:hover {
      background-color: #C6C6C6;
      color: #A8A8A8;
    }
  }
}

@mixin ingestOptionFormSmall {
  height: 294px;
  width: 677px;
  position: relative;
  color: #161616;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #EBEBEB;

  .contentWrapper {
    width: 100%;
    padding: 16px 27px;

    & h1 {
      font-size: 20px;
      margin-top: 3px;
    }

    & p {
      width: 60%;
      height: 44px;
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
      overflow: auto;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    & label {
      display: block;
      margin-top: 24px;
      font-size: 12px;
      width: 100%;
      position: relative;

      & > input {
        margin-top: 8px;
      }

      & > span {
        position: absolute;
        bottom: -15px;
        right: 0;
        font-size: 11px;
        color: $brightColor;
      }
    }

    input {
      @include defaultInput;
    }

    .error {
      position: absolute;
      font-size: 10px;
      line-height: 16px;
      color: $brightColor;
      right: 0;
      bottom: -18px;
    }
  }

  .buttons {
    @include actionButtons;
    margin-top: auto;
  }
}

@mixin customCheckbox {
  input[type="checkbox"] {
    cursor: pointer;
    height: 15px;
    width: 15px;
    border: none;
    background-color: white;
    -webkit-appearance: none;
    appearance: none;
  }

  input[type="checkbox"]:checked {
    background-color: #161616;
  }

  input[type="checkbox"]:checked:after {
    content: " ";
    position: absolute;
    right: 4px;
    top: 1px;
    width: 4px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@mixin container {
  width: 92%;
  margin: 0 auto;
  position: relative;
}

@mixin searchInput {
  position: relative;

  & > input {
    height: 32px;
    width: 100%;
    font-family: "Poppins-Regular", sans-serif;
    border-radius: 0;
    font-size: 14px;
    padding-left: 42px;
    margin: 0;

    &::placeholder {
      color: #A8A8A8;
    }

    &:focus,
    &:hover,
    &:active {
      border-radius: 0;
      border: 2px solid $brightColor;
      outline: none;
    }
  }
  & > img {
    position: absolute;
    top: 0;
    left: 6px;
  }
}

@mixin searchInputPink {
  position: relative;

  & > input {
    height: 40px;
    width: 100%;
    font-family: "Poppins-Regular", sans-serif;
    border-radius: 0;
    font-size: 14px;
    padding-left: 42px;
    margin: 0;
    background-color: #ac6181;
    border: none !important;
    color: white;

    &::placeholder {
      color: #bfbbbb;
      text-transform: uppercase;
    }

    &:focus,
    &:hover,
    &:active {
      border-radius: 0;
      border: 2px solid $brightColor;
      outline: none;
    }
  }
  & > img {
    width: 16px;
    position: absolute;
    filter: brightness(0.7);
    top: 12px;
    left: 13px;
  }
}

@mixin cartMark {
  position: absolute;
  width: 7px;
  height: 7px;
  right: 7px;
  top: 9px;
  border-radius: 50%;
  background-color: $brightColor;
}

@mixin appTableWhite {
  width: 100%;

  thead tr th {
    position: sticky;
    top: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
  }

  th {
    position: relative;
    background-color: transparent;
    padding: 10px 13px 27px 13px;
    line-height: 20px;
    font-weight: 700;
    color: white;
    text-align: start;
    font-size: 15px;
    cursor: pointer;
    min-width: 100px;
    white-space: nowrap;
    font-family: "Poppins-Bold", sans-serif;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
    }

    &::before {
      top: 0;
      background-color: white;
    }

    &::after {
      bottom: -2px;
      background-color: rgba(255, 255, 255, 0.33);
    }

    & > svg {
      width: 16px;
      height: 14px;
      margin-left: 3px;
    }
  }

  tbody {
    tr:last-child {
      border-bottom: 2px white solid;
    }
  }

  td {
    position: relative;
    min-width: 100px;
    background-color: transparent;
    padding: 10px 13px 20px 13px;
    font-weight: 500;
    color: white;
    text-align: start;
    font-size: 15px;
    line-height: 20px;
    font-family: "Poppins-Light", sans-serif;
  }
  .greyCell {
    color: #B2B2B2;
  }
}

@mixin appTable {
  width: 100%;
  thead tr th {
    position: sticky;
    top: 0;
  }

  th {
    position: relative;
    background-color: #E0E0E0;
    padding: 18px 13px 18px 13px;
    font-weight: 700;
    color: black;
    text-align: start;
    font-size: 13px;
    cursor: pointer;
    min-width: 100px;
    white-space: nowrap;

    & > svg {
      width: 16px;
      height: 14px;
      margin-left: 3px;
    }
  }

  td {
    position: relative;
    min-width: 100px;
    background-color: transparent;
    padding: 16px 13px 36px 13px;
    font-weight: 500;
    color: #525252;
    text-align: start;
    font-size: 13px;
    border-bottom: 2px solid #F4F4F4;
  }
}

@mixin tableChevron {
  position: absolute;
  top: 12px;
  left: 21px;
  transition: .3s;
}

@mixin calendar-day {
  li:hover {
    background-color: $brightColor;
  }
  & li.is-today {
    box-shadow: inset 0 0 0 2px $brightColor !important;
  }

  & li.is-selected-day {
    background-color: $brightColor !important;
  }

  .is-selected-day-from, .is-selected-day-to {
    background-color: $brightColor !important;
  }

  .is-selected-day-range {
    background-color: #ff34664d !important;
    color: #161616 !important;
  }

  & li.is-disabled {
    color: #8D8D8D !important;
    background-color: #F4F4F4 !important;
  }
}

@mixin calendar-month {
  font-family: "Poppins", sans-serif;
  font-size: 13px;

  .is-selected-month {
    color: $brightColor !important;
    font-size: 16px;
  }
}

@mixin calendar-year {
  .is-selectedYearRef {
    color: $brightColor !important;
    font-size: 20px !important;
    font-family: "Poppins", sans-serif;
  }
}

@mixin label {
  display: block;
  font-size: 16px;
  width: 100%;
  position: relative;
}

@mixin notActiveLink {
  text-decoration: none;
  font-weight: 500;
  color: $brightColor;
  font-family: Poppins-SemiBold, sans-serif;
  padding-bottom: 5px;

  &:hover {
    color: #FFFFFF;
  }
}

@mixin activeLink {
  text-decoration: none;
  font-weight: 600;
  font-family: Poppins-SemiBold, sans-serif;
  color: #FFFFFF;
  border-bottom: 2px solid #FF3466;
  padding-bottom: 5px;
}

@mixin downloadMenu {
  height: 100%;
  display: flex;
  & button {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 18px;
    background: none;
    border: none;
    border-left: 1px solid #3b3a3b;
    color: white;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;

    & img {
      margin-left: 28px;
      width: 16px;
      height: 16px;
    }
  }
  & button:last-child {
    border-right: 1px solid #3b3a3b;
  }
}


@mixin checkboxes {
  display: flex;
  flex-direction: column;

  & div {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  & div:last-child {
    margin: 0;
  }

  & span {
    margin-left: 10px;
    font-size: 14px;
    line-height: 21px;
    color: #161616;
  }
}

@mixin pageTitle {
  color: white;
  font-family: "ITC Avant Garde Bold", sans-serif;
  font-size: 32px;
  line-height: 40px;
}

@mixin table {
  width: 100%;
  table-layout: auto;

  thead tr th {
    position: sticky;
    top: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
  }

  th {
    position: relative;
    background-color: transparent;
    padding: 13px;
    font-weight: 700;
    color: white;
    text-align: start;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    min-width: 100px;
    white-space: nowrap;
    font-family: "Poppins-Bold", sans-serif;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      background-color: white;
    }

    &::before {
      top: 0;
      background-color: white;
    }

    &::after {
      bottom: -2px;
      background-color: rgba(255, 255, 255, 0.33);
    }

    & > svg {
      width: 16px;
      height: 14px;
      margin-left: 3px;
    }
  }

  td {
    position: relative;
    min-width: 100px;
    background-color: transparent;
    padding: 12px 16px 12px 12px;
    font-weight: 500;
    color: white;
    text-align: start;
    font-size: 15px;
    font-family: "Poppins-Light", sans-serif;
    cursor: default;

    & svg {
      cursor: pointer;
    }
  }
}

@mixin tableScroll {
  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.16);
  }

  ::-webkit-scrollbar-thumb {
    background: #FF3466;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #cc3359;
  }
}

@mixin appScroll {
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.16);
  }

  &::-webkit-scrollbar-thumb {
    background: #FF3466;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #cc3359;
  }
}

@mixin basicBackground {
  overflow-y: auto;
  padding-top: 115px;
}

@mixin deleteIconBig {
  width: 105px;
  height: 105px;
}

@mixin auditTrailsContentBox {
  position: relative;
  width: 100%;
  height: 75vh;
  z-index: 1;
  margin-top: 50px;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.16);
    z-index: -1;
  }
}

@mixin formScrollable {
  max-height: 85%;
  display: flex;
  flex-direction: column;
  width: 512px;
  position: fixed;
  color: #161616;
  background-color: #EBEBEB;
  z-index: 10;
  font-family: "Poppins-Regular", sans-serif;
}

@mixin formTitle {
  font-family: Poppins-SemiBold, sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #2B2A2B;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  padding: 0 15px 5px;
  border-bottom: 2px solid $brightColor;
  z-index: 100;
}

@mixin redTable {
  table {
    border-bottom: 2px solid #EA315F;
    border-top: 2px solid #EA315F;

    th {
      color: #EA315F;
      padding: 8px;
      background: transparent;
    }

    td {
      border-top: none;
      border-bottom: none;
      color: black;
      padding: 8px;
    }
  }
}

@mixin selectedRecordDetailsLine {
  font-size: 26px;
  line-height: 43px;
  font-family: "Poppins-SemiBold", sans-serif;
  color: white;
  & > span {
    display: inline-block;
    margin-right: 10px;
    color: $brightColor;
  }
}

@mixin formTitleUnderLined {
  position: relative;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  font-family: "Poppins-SemiBold", sans-serif;
  width: 100%;
  font-weight: 600;
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 200px;
    background: $brightColor;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@mixin resetFilterButton {
  position: absolute;
  top: 2px;
  right: 0;
  border: none;
  font-size: 12px;
  text-decoration: underline;
  background: transparent;
  font-family: "Poppins-regular", sans-serif;
  color: #FFFFFF;
  cursor: pointer;
  z-index: 1;
  &:hover {
    opacity: 0.8;
  }
}

@mixin logoUploader {
  .uploadLabel {
    width: 100%;
    margin-bottom: 25px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px dashed #8D8D8D;
    font-size: 12px;
    font-weight: bold;
    color: $brightColor;
    transition: 0.1s all ease-in-out;

    &:focus,
    &:hover,
    &:active {
      border-radius: 0;
      border: 1px solid $brightColor;
      outline: none;
    }

    &.dragActive {
      background-color: #E8E8E8;
    }

    &.uploaded {
      background-color: #E0E0E0;
    }
  }

  .uploadTextSection {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 400px;
    height: 100%;
    line-height: 20px;
  }

  .formTitle {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }

  .uploadTitle {
    font-size: 14px;
    color: #FF3466;
    font-weight: bold;
  }

  .uploadDescription {
    color: #808080;
    margin-top: auto;
    font-style: italic;
    font-weight: initial;
    line-height: 20px;
  }

  .uploadInput {
    display: none;
  }

  .coverElement {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
  }

  .oneInputContainer {
    display: flex;

    & > label {
      max-width: 100%;
    }
  }
}

@mixin operatorBillingTable {
  & > table {
    width: 100%;
    table-layout: fixed;
    color: #FFFFFF;
    background-color: inherit;
    font-size: 16px;
    line-height: 25px;

    thead tr th {
      position: sticky;
      top: 0;
    }

    & th {
      border-top: 2px solid #F4F4F4;
      border-bottom: 1px solid #F4F4F4;
      font-family: 'Poppins-Bold', sans-serif;
      padding: 13px 10px 0 0;
      text-align: start;

    }

    td {
      font-family: 'Poppins-Regular', sans-serif;
      padding: 13px 10px 0 0;
    }
  }
}

@mixin topButtons {
  display: flex;
  align-items: center;

  & button {
    background: transparent;
    color: #FFFFFF;
    outline: none;
    border-style: none;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    text-decoration: underline;

    & > svg {
      margin-left: 5px;
      margin-top: -2px;
    }
  }

  & button:last-child {
    padding-right: 0;
  }
}

@mixin issueFormTextarea {
  width: 100%;
  min-height: 120px;
  resize: none;
  padding: 16px;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  margin-top: 10px;
  outline: none;
  border-style: none;

  &::placeholder {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
  }
}

@mixin reportingSatelliteForm {
  position: relative;
  color: #161616;
  opacity: 1;
  background-color: #EBEBEB;
  z-index: 10;

  & h1 {
    font-size: 20px;
    margin-bottom: 2px;
  }

  .checkboxGroup {
    display: flex;
    justify-content: space-between;
  }

  .calendar {
    font-size: 16px;
    margin-top: 10px;
    position: relative;
    width: 220px;

    .calendarInputWrapper {
      margin-top: 8px;
      height: 48px;
    }
  }

  .coverElement {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
  }
}

@mixin issueFormTitle {
  position: relative;
  text-align: center;
  font-size: 20px;
  color: #000000;
  font-family: "Poppins-SemiBold", sans-serif;
  padding-bottom: 9px;
  padding-top: 9px;
  margin-bottom: 15px;

  &:after {
    content: '';
    display: block;
    width: 200px;
    height: 2px;
    background-color: #EA315F;
    margin: 10px auto 0;
    bottom: 100px;
  }
}

@mixin uploadLabel {
  width: 100%;
  margin-top: 22px;
  margin-bottom: 25px;
  padding: 21px 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed #8D8D8D;
  font-size: 16px;
  font-weight: bold;
  color: $brightColor;
  transition: 0.1s all ease-in-out;

  &:focus,
  &:hover,
  &:active {
    border-radius: 0;
    border: 2px solid $brightColor;
    outline: none;
  }

  &.dragActive {
    background-color: #E8E8E8;
  }

  &.uploaded {
    background-color: #E0E0E0;
  }
}

@mixin contentLabel {
  width: 150px;
  height: 80px;
  background-color: white;
  font-size: 22px;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  line-height: 29px;
  font-weight: 600;

  & > span {
    font-family: "Poppins-Bold", sans-serif;
    font-size: 27px;
  }
}

@mixin backButton {
  height: 30px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  background-color: inherit;
  font-size: 18px;
  border: none;
  cursor: pointer;
  color: #FFFFFF;
  font-family: Poppins-Regular, sans-serif;

  & > svg {
    margin-right: 10px;
    color: #FFFFFF;
  }

  &:hover {
    color: $brightColor;

    & > svg {
      color: $brightColor;
    }
  }
}

@mixin bookFixturesFilters {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 4;

  .inputWrapper {
    min-width: 240px;
  }

  .label {
    display: block;
    font-size: 16px;
    width: 100%;
    position: relative;
    font-family: "Poppins-regular", sans-serif;
    color: #FFFFFF;
    line-height: 25px;
  }
}
