@import "../../../../../../../mixins";
@import "../../../../../../../variables.module";

.accordionCell {
  position: relative;
  cursor: pointer;
}

.tableChevron {
  position: absolute;
  top: 12px;
  left: 0;
  color: $brightColor;
}

.tableChevronTop {
  transform: rotate(-180deg);
  top: 8px;
}

.operatorCell {
  padding-left: 25px;
  font-family: 'Poppins-Bold', sans-serif;
}

.titleCell {
  width: 200px;
}
