@import '../../../src/variables.module';

.app-select__control {
  margin-top: 8px;
  padding: 0 10px;
  height: 48px;
  border: none !important;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  transition: .3s !important;
  flex-wrap: nowrap !important;
  position: relative;

  &--is-focused {
    box-shadow: 0 0 0 2px $brightColor !important;
    border-radius: 0 !important;
  }

  .app-select__input-container {
    padding: 0;
    margin: 0;
  }

  .app-select__indicator {
    & > svg {
      color: #161616;
    }
  }

  .app-select__indicator-separator {
    display: none;
  }

  .app-select__placeholder {
    color: #8D8D8D;
  }

  .app-select__single-value {
    color: #161616;
    height: 48px;
    line-height: 48px;
    margin: 0;
  }

  .app-select__clear-indicator {
    padding: 0 5px 0 0;
  }

  .app-select__dropdown-indicator {
    padding: 0;
  }

  .app-select__value-container {
    margin: 0 0 2px 0;
    padding: 0;
    height: inherit;
    overflow: visible;
  }
}

.css-13cymwt-control {
  background-color: inherit !important;
  overflow: visible !important;
}

.app-select__menu {
  z-index: 20 !important;
  border-radius: 0 !important;

  &-list {
    padding: 0 !important;

    & .app-select__option {
      padding: 15px;
      color: #161616;
      font-size: 14px;

      &:hover {
        background-color: #E8E8E8;
      }

      &--is-focused {
        background-color: #E0E0E0;
      }

      &--is-selected {
        background-color: #E0E0E0;
        position: relative;
      }
    }
  }
}

.app-select__multi-value {
  margin: 2px;
  padding: 4px;
  background-color: #393939 !important;
  border-radius: 12px !important;
  justify-content: space-between;
  align-items: center !important;
  width: 70px;

  & > div:first-child {
    color: #FFFFFF;
    font-size: 11px;
    padding: 0 2px 0 0;
  }

  & > div:nth-child(2) {
    color: #FFFFFF;
    padding: 0;

    &:hover {
      background-color: #383838;
    }
  }
}

.app-select__multi-value--is-disabled {
  background: #868585FF !important;
}

.selected-checkmark {
  display: inline-block;
  width: 6px;
  height: 12px;
  border: solid #161616;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  right: 8px;
  bottom: 2px;
}

.select-inner {
  position: relative;
}

.expander {
  position: absolute;
  right: 8px;
  z-index: 1;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  font-size: 20px;

  span {
    font-size: 20px;
  }

  .collapsed-icon {
    display: block;
    transform: translateX(-1px);
  }

  .expanded-icon {
    display: block;
  }
}
