.geoChart {
  padding: 35px 16px 77px 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: rgba(0, 0, 0, 0.16);
  position: relative;
}

.geoLegendInner {
  width: 90%;
  position: absolute;
  bottom: 30px;
  padding-left: 80px;
  padding-right: 80px;
}

.geoChartUnknown {
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  position: absolute;
  bottom: 70px;
  left: 50%;
  color: #FFFFFF;

  & > span {
    font-weight: 800;
  }
}

.gradientInner {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.gradient {
  position: relative;
  width: 100%;
  height: 20px;
  background: linear-gradient(0.25turn, #EDEDED, #F2BDCA, #F690A9, #FF3566);
}

.pointer {
  position: absolute;
  top: -18px;
}

.minLegendValue {
  color: #979697;
}

.maxLegendValue {
  color: #FFFFFF;
}
