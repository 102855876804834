@import "../../../../../src/variables.module";

.sidebar {
  position: fixed;
  top: 70px;
  left: 0;
  width: 350px;
  height: 100%;
  background-color: white;
  color: black;
  z-index: 100;
  transition: 0.3s ease-in-out;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-family: 'Poppins-Regular', sans-serif;

  &::-webkit-scrollbar {
    display: none;
  }
}

.hidden {
  left: -360px;
}

.menu {
  display: flex;
  flex-direction: column;
}

.menuTab, .menuTab > a {
  display: block;
  min-height: 42px;
  font-size: 18px;
  color: black;
  text-decoration: none;
  line-height: 42px;
  cursor: pointer;
}

.menuTab > a, .menuTab > div {
  padding-left: 16px;
  position: relative;
  font-family: 'Poppins-SemiBold', sans-serif;

  &:hover {
    background-color: #F4F4F4;
  }
}

a:hover {
  background-color: #F4F4F4;
}

.menuTabActive {
  border-left: 4px solid $brightColor;
  background-color: #F4F4F4;

  & > a {
    color: black;
    text-decoration: none;
  }
}

.subMenuTab, .subMenuTab > a {
  display: block;
  min-height: 42px;
  color: black;
  font-size: 17px;
  text-decoration: none;
  line-height: 42px;
  cursor: pointer;
}

.subMenuTab > a, .subMenuTab > div {
  position: relative;
  padding-left: 32px;

  &:hover {
    background-color: #F4F4F4;
  }
}

.subMenuTabActive {
  border-left: 4px solid $brightColor;
  background-color: #F4F4F4;

  & > a, div {
    padding-left: 28px;
    color: #161616;
    text-decoration: none;
  }
}

.subMenu {
  a {
    padding-left: 50px;
  }
}

.test {
  background-color: gold !important;
}
