.rrt-text {
  word-break: keep-all;
}

.toastr {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 16px;

  .close-toastr {
    height: 40px !important;
    color: black !important;
  }

  &.rrt-success {
    background-color: #0ABAB5 !important;

    .rrt-progressbar {
      background-color: darken(#0ABAB5, 15%) !important;
    }
  }

  &.rrt-warning {
    background-color: #FFFFFF !important;
    color: black !important;

    .rrt-progressbar {
      background-color: #F4F4F4 !important;
    }

    .toastr-icon {
      fill: #FF3466 !important;
      width: 52px !important;
      height: 52px !important;
    }

    .rrt-title {
      color: #FF3466 !important;
      font-family: 'Poppins-SemiBold', sans-serif !important;
      font-size: 26px !important;
    }

    .rrt-text {
      font-family: 'Poppins-SemiBold', sans-serif !important;
      font-size: 14px !important;
      line-height: 18px;
    }
  }

  &.rrt-error {
    background-color: #ff3466 !important;

    .rrt-progressbar {
      background-color: darken(#EA4969, 15%) !important;
    }
  }
}
