.downloadContainer {
  display: flex;
  height: 100%;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.csvBtn {
  display: flex;
  color: #FFFFFF;
  background-color: inherit;
  align-items: center;
  height: 100%;
  padding: 0 16px 0 16px;
  border: none;
  border-left: 1px solid #525252;
  border-right: 1px solid #525252;
  text-decoration: underline;

  & img {
    width: 16px;
    height: 16px;
    margin-left: 15px;
    margin-bottom: 5px;
  }
}

.csvBtn:hover {
  cursor: pointer;
}
