.streamPopup {
  position: fixed;
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  box-shadow: 0 12px 42px 2px rgba(0, 0, 0, 0.80);

  .closePopupButton {
    position: absolute;
    background: transparent;
    border: none;
    padding: 0;
    top: 50px;
    right: 20px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 101;

    &.topButton {
      top: 20px;
    }
  }

  .closePopupIcon {
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .formatSelectionPanel {
    height: 30px;
    width: 100%;
    display: flex;

    .formatButton {
      font-size: 16px;
      color: white;
      line-height: 30px;
      width: 50%;
      font-family: "Poppins-Regular", sans-serif;
      text-align: center;
      background-color: #2B2A2B;
      transition: 0.2s all ease-in-out;
      cursor: pointer;
      border: none;
      padding: 0;

      &:first-child {
        border-right: 1px solid #5e5e5e;
      }

      &:nth-child(2) {
        border-left: 1px solid #5e5e5e;
      }

      &:hover {
        background-color: rgb(72, 71, 71);
      }
    }
  }
}
