@import "../../../mixins";

.pageContainer {
  @include container;

  .bodyWrapper {
    background-color: white;
    margin-top: 48px;
  }

  .pageTitle {
    height: 72px;
    margin-top: 50px;
    font-size: 28px;
    padding: 10px 16px;
    background-color: white;
  }

  .menu {
    background-color: $brightColor;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .resourceIdInput {
    @include defaultInput;
    width: 250px;
  }

  .showTrailBtn {
    color: white;
    border: none;
    background-color: $brightColor;
    font-family: "Poppins-regular", sans-serif;
    cursor: pointer;
    padding: 18px;
    font-size: 14px;
    line-height: 14px;

    &:hover {
      background-color: #EA315F;
      transition: 0.2s ease-in-out;
    }
  }
}

.userDetails {
  font-size: 26px;
  line-height: 34px;
  font-family: "Poppins-SemiBold", sans-serif;
  color: white;
  margin: 15px 0;
  padding-left: 10px;

  & > span {
    display: inline-block;
    margin-right: 10px;
    color: $brightColor;

    &:nth-child(2) {
      margin-left: 45px;
    }
  }
}
