.headerClickable {
  &:hover {
    filter: brightness(0.85);
    transition: 0.2s ease-in-out;
  }
}

.headerClickableSelected {
  filter: brightness(0.85);
}

thead tr th {
  position: fixed;
  z-index: 1;
}

.complexTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;

  & > span {
    color: white;
  }
}

.nonClickable {
  cursor: default !important;
}

.contentSupplierColumn {
  max-width: 120px;
  white-space: pre-line !important;
  padding-bottom: 13px !important;

  .arrow {
    position: absolute;
    top: 12px;
    left: 77px;
  }
}

.arrow {
  display: inline-block;
  transform: translate(3px, 2px);
}

.headerActions {
  text-align: center !important;
}

