@import "../../../../mixins";
@import "../../../../variables.module";

.donut {
  margin-top: 37px;
  width: 375px;
  padding: 0 15px;
  position: relative;

  .donutChartLabel {
    display: none;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 4px;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s;
    font-family: "Poppins", sans-serif;
    z-index: 100;
    pointer-events: none;
  }

  .donutChartLabelActive {
    display: block;
    transition: 0.3s;
  }

  .donutTotal {
    width: 58%;
    word-break: break-word;
    position: absolute;
    top: 170px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;

    & > p:first-child {
      font-size: 28px;
      font-family: "Poppins-Light", sans-serif;
      line-height: 39px;
      color: #FFFFFF;
    }

    & > p:nth-child(2) {
      font-size: 28px;
      color: #FFFFFF;
      font-family: "Poppins-SemiBold", sans-serif;
    }
  }
}

.chartInner {
  position: relative;
}

