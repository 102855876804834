@import '../../../../../../src/mixins';
@import '../../../../../../src/variables.module';

.overlay {
  @include modalOverlay;
  z-index: 1000;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 462px;
    position: fixed;
    color: #161616;
    background-color: #EBEBEB;
    z-index: 10;
    font-family: "Poppins-Regular", sans-serif;

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      padding: 17px 17px 45px;
    }

    .titleInner {
      display: flex;
      justify-content: center;
    }

    .title {
      @include formTitle;
    }

    .label {
      @include label;
    }

    .input {
      @include defaultInput;
    }

    .buttons {
      @include actionButtons;
    }
  }
}

.formErrorBlock {
  @include formErrorBlock;
}

.checkboxInner {
  display: flex;
  justify-content: space-between;
}

.checkbox {
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: end;
  font-size: 12px;

  & > span,
  & > span:hover {
    padding-right: 10px;
    cursor: default;
    color: black;
  }
}

.fixtureLabel {
  font-size: 12px;
}
