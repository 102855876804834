.switcher {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;

  & button {
    width: 48px;
    height: 48px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: rgba(0, 0, 0, .16);
    cursor: pointer;

    & > svg {
      width: 24px;
      height: 24px;
    }
  }

  & button:last-child {
    border-left: none;
  }
}
