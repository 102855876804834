@import "../../mixins";
@import "../../variables.module";

.downloads {
  @include downloadMenu;
}

.filter {
  max-width: 80%;
}

.container {
  @include container;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 10;
}

.analyticsPage {
  @include basicBackground;

  .filtersSectionWrapper {
    width: 100%;
    z-index: 3;

    .filtersSection {
      @include container;
      padding: 70px 0 0 15px;
      position: relative;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 32px;
        font-family: "ITC Avant Garde Bold", sans-serif;
        margin-bottom: 30px;
        color: #FFFFFF;
      }

      .calendar {
        position: relative;
        width: 320px;
        height: 61px;
        color: #6F6F6F;

        input {
          border: none;
          border-bottom: 1px solid #8D8D8D;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          background-color: #F4F4F4;
          font-family: "Poppins-Regular", sans-serif;
          transition: 0.2s all ease-in-out;
        }

        & > div {
          line-height: 13px;
        }

        & > div > div {
          height: 40px;
          margin-top: 8px;

          & > div:first-child {
            height: 100%;
          }
        }

        input:hover {
          background-color: #E8E8E8;
        }

        input::placeholder {
          color: #6F6F6F;
        }
      }
    }
  }

  .mainSection {
    margin-top: 20px;

    .charts {
      display: grid;
      grid-template-columns: 530px 2fr;
      gap: 20px;
      justify-content: space-between;
      align-items: stretch;
      max-height: 540px;
      margin-bottom: 20px;
      overflow: hidden;

      .unfolded {
        position: relative;
        max-width: 566px;
        min-width: 100px;
        padding: 16px 15px 30px 16px;
        background: rgba(0, 0, 0, 0.16);
      }

      .closeButton {
        position: absolute;
        right: 16px;
        top: 30px;
        width: 32px;
        height: 32px;
        padding: 0;
        border: 0;
        background: none;
        cursor: pointer;
        z-index: 4;

        &:hover {
          background-color: #E8E8E8;
        }

        & > img {
          width: 20px;
          height: 20px;
        }
      }

      .folded {
        position: relative;
        width: 63px;
        padding-top: 16px;
        border-right: 2px solid #F4F4F4;
        text-align: center;

        & > img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
  }

  .breakdownsAndBookmarks {
    @include container;
    display: flex;
    justify-content: flex-end;
    padding: 15px;
  }
}





