@import '../../../src/variables.module';
@import "../../../src/mixins";

.range-input {
  width: 100% !important;
  height: 48px !important;
  line-height: 100% !important;
  padding: 16px 0 14px 16px !important;
  text-align: left !important;
  border-radius: 0 !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  border: none;
  background: inherit;
}

.calendar-header {
  background-color: $brightColor !important;
}

.rangeLabels {
  background-color: $brightColor !important;
}

.calendar-day {
  @include calendar-day;
}

.yearWrapper {
  @include calendar-year;
}

.monthList {
  @include calendar-month;
}

.calendar-modal-up {
  bottom: 45px;
}

.calendar-modal-down {
  top: 45px;
  height: fit-content;
}

.analytics-input {
  background-color: #F4F4F4;
  padding: 13px 0 10px 13px !important;
  color: transparent;

  &::placeholder {
    color: transparent;
  }

  &::selection {
    color: transparent
  }
}
