.passwordHelper {
  width: 100%;
  padding: 12px;
  background-color: #F4F4F4;

  .helperContentInner {
    font-family: "Poppins-Regular", sans-serif;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 18px;
    color: #525252;
  }
}

.stateMark {
  color: #00c58a;
  margin-right: 10px;
  font-size: 12px;
}

.stateMarkInvalid {
  color: #EA315F;
  margin-right: 10px;
  font-size: 12px;
}
