@import '../../../../src/mixins';

$form-font-size: calc(9px + 0.5vh);
$form-font-size-big: calc(10px + 0.5vh);

.loginLeftSide {
  position: relative;
  width: 50%;
  height: 100vh;
  background-size: cover;
  font-family: "ITC Avant Garde Regular", sans-serif;
  background: linear-gradient(20deg, #6d3fa8, #b30e3f);

  .titleText {
    position: absolute;
    top: 38%;
    left: 15%;
    color: white;

    &Welcome {
      font-size: 3.4vh;
    }

    &ProjectName {
      margin-top: 13px;
      font-size: 4vw;

      font-family: inherit;
      font-weight: 700;

      &Bold {
        font-family: "ITC Avant Garde Bold", sans-serif;
      }
    }
  }

  .logoPoweredBy {
    position: absolute;
    bottom: 40px;
    left: 43px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: white;
    font-size: 1.6vh;
    line-height: 2.5vh;

    & > img {
      width: 12vw;
    }
  }
}

.loginRightSide {
  position: relative;
  width: 50%;
  background-color: white;
  z-index: 5;

  .loginForm {
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translateX(-50%);
    width: 24vw;

    &Title {
      font-size: 2.5vh;
      font-family: "ITC Avant Garde Regular", sans-serif;
      font-weight: 700;
    }

    &ForgotPassword {
      font-family: "Poppins-Regular", sans-serif;
      font-size: $form-font-size;
      line-height: 1.7vh;
      color: #FE3466;
      opacity: 1;
      cursor: pointer;
    }

    .loginInputs {
      margin-top: 0.8vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &Label {
        position: relative;
        font-family: "Poppins-Regular", sans-serif;
        margin-top: 2.4vh;
        font-size: $form-font-size;
        color: #6E6E6E;
      }

      .loginInput {
        @include defaultInput;
        padding-top: 1.3vh;
        padding-bottom: 1.3vh;
        padding-left: 1.5vw;
        width: 100%;
        font-size: $form-font-size;
        margin-top: 0.8vh;
        background-color: #F4F4F4;
        height: 41px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 1000px #F4F4F4 inset !important;
        }

        &Error {
          border: 1px solid #FE3466;
          background-color: rgba(254, 52, 102, 0.09);
          transition: 0.5s all;
        }
      }
    }

    .loginHelpers {
      margin-top: 1.8vh;
      height: 2.4vh;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .inputStaySigned {
        display: none;

        & + label {
          position: relative;
          padding-left: 30px;
          cursor: pointer;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            background: #151515;
          }

          &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 9px;
            height: 6px;
            border-left: 2px solid white;
            border-bottom: 2px solid white;
            transform: rotate(-45deg);
            opacity: 0;
          }
        }
      }

      .inputStaySigned:checked + label:after {
        opacity: 1;
      }

      label {
        font-family: "Poppins-Regular", sans-serif;
        display: inline-block;
        position: relative;
        font-size: $form-font-size-big;
        line-height: 22px;
        color: #151515;
        opacity: 1;
        margin-right: 1.8vw;
      }

      & > img {
        width: 16px;
        height: 16px;
        margin-right: auto;
      }
    }

    .loginSignInBtn {
      position: absolute;
      right: 0;
      margin-top: 5vh;
      width: 14vw;
      height: 5.1vh;
      text-align: center;
      line-height: 5vh;
      font-family: "Poppins-Regular", sans-serif;
      background-color: #ff3466;
      color: rgb(255, 255, 255);
      font-size: calc(6px + 1.4vh);
      border: none;
      transition: all 0.3s;

      &:hover {
        background-color: #EA315F;
      }

      &:active {
        background-color: #991F3D;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #F4F4F4 inset !important;
}

.formError {
  @include formErrorBlock;
  margin-top: 5px;
}

@media (max-width: 1340px) {
  .loginFormForgotPassword {
    font-size: 11px !important;
    line-height: 11px !important;
    padding-top: 5px;
  }
  .rememberLabel {
    font-size: 11px !important;
    line-height: 11px !important;
    padding-top: 5px;
  }
}

@media (max-width: 1070px) {
  .loginFormForgotPassword {
    font-size: 10px !important;
    line-height: 10px !important;
    padding-top: 5px;
  }
  .rememberLabel {
    font-size: 10px !important;
    line-height: 10px !important;
    padding-top: 5px;
  }
}
