@import "../../../../../../mixins";

.tableWrapper {
  max-height: 53vh;
  overflow: auto;
  @include appScroll;
  padding-right: 20px;
  scrollbar-gutter: stable;

  ::-webkit-scrollbar-track {
    margin-top: 64px;
  }
}

.bodyRow {
  .deletedCol {
    background-color: #E8E8E8;
    color: #C6C6C6;
  }
}

.actionsTitle {
  width: 200px;
  padding-left: 42px !important;
}

.actionsIcons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0 25px;
}

.tooltip {
  background-color: #FF3466 !important;
  padding: 5px 7px !important;
  font-size: 12px;
  opacity: 1;
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 1000;
}

.actionBtn {
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: #FFFFFF;
  outline: none !important;
}

.disabledActionBtn {
  width: 18px;
  height: 18px;
  color: #D1D1D1;
  cursor: default;
}

.searchMessage {
  width: 100%;
  text-align: center;
  height: 60px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
