@import "../../../../../mixins";
@import "../../../../../variables.module";

.actionCell {
  text-align: center !important;
}

.actionBtn {
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  width: 28px;
  height: 28px;

  & > img {
    width: 100%;
  }
}
