@import "../../../../mixins";

.pageWrapper {
  background-color: #F4F4F4;
}

.pageContainer {
  @include container;
  padding-bottom: 72px;
}

.tableContainer {
  background: #FFFFFF;
}

.rangeContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 9px;
}

.billingTable {
  width: 100%;
  table-layout: fixed;
  padding: 0 17px;

  thead tr th {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    text-align: left;
  }

  thead tr th:last-child {
    width: 53px;
  }

  th {
    position: relative;
    background-color: #E0E0E0;
    padding: 15px 0 15px 16px;
    font-weight: 700;
    line-height: 21px;
    color: #161616;
    font-size: 12px;
  }
}

@media screen and (min-width: 1200px) {
  .billingTable {
    th {
      font-size: 14px;
    }
  }
}

.range {
  width: 270px;
}