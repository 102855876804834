@import '../../../../../src/mixins';
@import '../../../../../src/variables.module';

.page {
  @include basicBackground;

  .pageContainer {
    @include container;
    padding-bottom: 40px;
  }

  .title {
    font-size: 32px;
    font-family: "ITC Avant Garde Bold", sans-serif;
    line-height: 40px;
    color: #FFFFFF;
  }
}

.csvBtn {
  outline: none;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  font-family: "Poppins-Regular", sans-serif;
  background: none;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}

.favourite {
  height: 38px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #FFFFFF;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  padding-top: 10px;

  p {
    margin-right: 5px
  }

  .star {
    font-size: 30px;
    cursor: pointer;
  }

  div {
    cursor: pointer;
  }
}


.chartsControls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;

  .leftControls {
    position: relative;
    width: 340px;
    height: 79px;
    border-radius: 0 30px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.16);
      z-index: 1;
      border-radius: 0 30px 0 0;
    }
  }

  .rightControls {
    position: relative;
    width: 490px;
    height: 79px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.16);
      z-index: 1;
      border-radius: 30px 0 0 0;
    }
  }
}

.btnLRadius {
  position: relative;
  z-index: 1;
  width: 155px;
  height: 31px;
  outline: none;
  background: #FF3466;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  cursor: pointer;
  transition: ease-in .2s;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
}

.btnRRadius {
  position: relative;
  z-index: 1;
  width: 145px;
  height: 31px;
  outline: none;
  background: #dadada;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  cursor: pointer;
  transition: ease-in .2s;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
}

.btnMiddle {
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 145px;
  height: 31px;
  outline: none;
  background: #dadada;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  transition: ease-in .2s;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
}

.activeBtn {
  background: #FF3466;
  transition: ease-in .2s;
}

.notActive {
  background: #b2b2b2;
  transition: ease-in .2s;
}


.chartSection {
  position: relative;
  width: 100%;
  height: 720px;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.16);
    z-index: -1;
  }

  .leftSideLabel {
    top: 50%;
    left: -20px;
    position: absolute;
    transform: rotate(-90deg);
    color: #FFFFFF;
    font-family: Poppins-Light, sans-serif;
    font-size: 15px;
  }

  .sectionHeader {
    z-index: 1000;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 40px;
    padding-right: 20px;

    .title {
      font-size: 28px;
      font-family: "Poppins-Regular", sans-serif;
    }

    .headerLabels {
      position: relative;
      display: flex;
      column-gap: 10px;

      .typeBtn {
        position: absolute;
        bottom: -30px;
        right: 0;
        display: flex;
        column-gap: 10px;
        color: #FFFFFF;
        z-index: 100;

        .typeBtnLabel {
          font-size: 14px;
          font-family: "Poppins-Light", sans-serif;
          text-transform: capitalize;
          white-space: nowrap;
        }
      }

      .label {
        @include contentLabel;
      }

      .primary {
        background: #FFFFFF;
      }

      .secondary {
        background: #B2B2B2;
        color: #FFFFFF;
      }
    }
  }
}

.chartBody {
  position: relative;
}

.tableInner {
  padding-top: 140px;
  padding-left: 40px;
  padding-right: 40px;
}

.additionalCart {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;

  img {
    height: 15px;
    width: 15px;
  }
}

.mainCart {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}

.cartBtn {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;

  .cartIcon {
    font-size: 15px;
    color: #FFFFFF;
  }

  & > div {
    color: #FFFFFF;
    font-size: 15px;
  }
}

.bookedStatus {
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-family: "Poppins-Light", sans-serif;
  line-height: 18px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}

.timezoneBlock {
  position: absolute;
  bottom: 10%;
  left: calc(50% - 86px);
  display: flex;
  justify-content: center;

  .timezoneInput {
    width: 130px;
  }
}

.noDataTemplate {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
}

.csvWrapper {
  @include downloadMenu;
}

@media (min-height: 1120px) {
  .page {
    @include basicBackground;
  }
}

.starButton {
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: inherit;
}
.addToCartButton {
  background: transparent;
  outline: none;
  border-style: none;
}
.addToCartIcon {
  width: 24px;
  height: 24px;
}
