@import "../../../../mixins";
@import "../../../../variables.module";


.page {
  @include basicBackground;

  .pageContainer {
    @include container;
    padding-bottom: 40px;
  }

  .upperSection {
    display: flex;
    justify-content: space-between;
    padding: 28px 0 34px;

    .title {
      @include pageTitle;
    }

    .filters {
      width: 500px;
      height: 70px;
      background-color: white;
    }
  }

  .tableOverlay {
    background: rgba(0, 0, 0, 0.16);

    .tableTitleSection {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 16px 16px 45px 40px;
    }

    .tableTitle {
      color: white;
      font-size: 27px;
      margin-top: 10px;
      font-family: "Poppins-Light", sans-serif;
    }

    .tableLegend {
      @include contentLabel;
    }
  }
}

.playIcon {
  width: 18px;
  height: 18px;
  background-color: #B23553;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 25%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 7px solid #ffffff;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  position: absolute;
  left: 5px;
  bottom: 4px;
}

.downloadButton {
  position: absolute;
  right: -5px;
  border: none;
  background-color: transparent;
  height: 49px;
  color: white;
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
  font-family: "Poppins-Bold", sans-serif;

  &.disabled {
    cursor: default;
    color: #787878;
  }
}

.downloadsWrapper {
  @include downloadMenu;
}
