@import "../../mixins";
@import "../../variables.module";

.title {
  font-size: 28px;
  padding: 10px 0 0 16px;
  font-family: Poppins-Regular, sans-serif;
  cursor: default;
}

.options {
  position: relative;
  height: 34px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > img {
    cursor: pointer;
  }

  & > svg {
    width: 22px;
    height: 22px;
    color: black;
    margin: 0 6px 0 6px;
    cursor: pointer;
  }

  .cartMark {
    @include cartMark
  }

  .searchInput {
    @include searchInput;
    margin-right: 30px;
    width: 512px;
  }
}

.menu {
  height: 50px;
  background-color: $brightColor;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;

  & > div {
    padding: 18px;
    text-align: center;
    height: 100%;

    &:nth-child(2) {
      margin-left: auto;
      position: relative;

      &::after {
        content: "|";
        position: absolute;
        right: 0;
      }
    }
  }

  .menuButton {
    cursor: pointer;

    &:hover {
      background-color: #EA315F;
      transition: 0.2s ease-in-out;
    }
  }
}

.searchBar {
  background-color: $brightColor;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  height: 50px;
  width: 100%;
}
