.realCheckbox {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
  transition: 0.3s all;

  &:checked + .customCheckbox:before {
    content: '';
    display: inline-block;
    background-image: url(../../img/checkedIcon.svg);
    transition: 0.2s ease-in;
  }

  &:disabled + .customCheckbox:before {
    content: '';
    display: inline-block;
    background: transparent;
    border: 1.25px solid #A8A8A8;
    transition: 0.2s ease-in;
  }

  &:checked:disabled + .customCheckbox:before {
    content: '';
    display: inline-block;
    background-image: url(../../img/checkedIconDisabled.svg);
    border: 1.25px solid #C6C6C6;
    transition: 0.2s ease-in;
  }
}

.customCheckbox {
  position: relative;
  display: inline-block;
  height: 15px;
  width: 15px;
  background: transparent;
  cursor: pointer;
  border: 1.25px solid #FF3466;

  &.filled {
    background: white;
  }

  &:before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    top: -2px;
  }
}
