.background {
  background: rgba(0, 0, 0, 0.16);
  height: 242px;
  padding: 25px 34px;
}

.header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: 55px;
  border-top: 2px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  gap: 16px;

  h6 {
    text-align: center;
    align-self: center;
    justify-self: start;
    font-size: 16px;
    font-family: "Poppins-Bold", sans-serif;
    color: #FFFFFF;
  }
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  color: white;
  padding-top: 15px;
  gap: 16px;
  border-bottom: 2px solid #FFFFFF;
  padding-bottom: 15px;
}

.statsCell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 30%;

  .name {
    font-size: 16px;
    font-family: "Poppins-Light", sans-serif;
    text-transform: capitalize;
  }

  .value {
    font-size: 16px;
    font-family: "Poppins-SemiBold", sans-serif;
  }
}

.oneStat {
  font-size: 28px;
  font-family: "Poppins-SemiBold", sans-serif;
}

