@import "../../../../../mixins";

.pageInner {
  @include basicBackground;
}

.pageContainer {
  @include container;
  padding-bottom: 40px;
}

.billingWrapper {
  display: flex;
  margin-top: 5px;
}

.userTableContainer {
  display: flex;
}

.tableWrapper {
  background-color: rgba(0, 0, 0, .16);
  padding: 23px;
  width: 100%;
}

.billingTable {
  @include operatorBillingTable;
}

.accordionCellInner {
  width: 140px;
}

.widthCell {
  width: 180px;
}

@media screen and (max-width: 1100px) {
  th {
    word-break: break-word;
  }

  th, td {
    font-size: 12px !important;
  }

  .accordionCellInner {
    width: 60px;
  }

  .widthCell {
    width: 80px;
  }
}

@media screen and (min-width: 1100px) {
  th, td {
    font-size: 14px !important;
  }

  .accordionCellInner {
    width: 70px;
  }

  .widthCell {
    width: 95px;
  }
}

@media screen and (min-width: 1200px) {
  th, td {
    font-size: 15px !important;
  }

  .accordionCellInner {
    width: 90px;
  }

  .widthCell {
    width: 120px;
  }
}

@media screen and (min-width: 1350px) {
  th, td {
    font-size: 16px !important;
  }

  .accordionCellInner {
    width: 110px;
  }

  .widthCell {
    width: 150px;
  }
}

@media screen and (min-width: 1500px) {
  .accordionCellInner {
    width: 150px;
  }

  .widthCell {
    width: 180px;
  }
}
